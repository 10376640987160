export const domain = 'JobStatusModel'

export const onInit = `${domain}/onInit`

export const onUpdateJobStatusData = `${domain}/onUpdateJobStatusData`
export const onUpdateJobStatusDataSuccess = `${domain}/onUpdateJobStatusDataSuccess`

export const onFetchJobStatuses = `${domain}/onFetchJobStatuses`
export const onFetchJobStatusesSuccess = `${domain}/onFetchJobStatusesSuccess`

