import getSentryClient from '../../SentryClient';
import * as SentryFilters from '../../SentryClient/utils/filters';
import isDev from '../utils/isDevEnv';
import { SentryClientInterface } from '../../SentryClient/SentryClientInterface';
import { getConfig } from './config';
import { AppConfigInterface } from 'common/types';

const config: AppConfigInterface = getConfig();

const sentryClient: SentryClientInterface = getSentryClient(
  { sentryDSN: config.sentryDSN },
  [SentryFilters.isEventError]
);

if (!isDev()) {
  sentryClient.init();
}

export default sentryClient;
