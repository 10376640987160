export const domain = 'UserAppsModel'

export const onCreateUserApp = `${domain}/onCreateUserApp`
export const onCreateUserAppSuccess = `${domain}/onCreateUserAppSuccess`

export const onUpdateUserApp = `${domain}/onUpdateUserApp`
export const onUpdateUserAppSuccess = `${domain}/onUpdateUserAppSuccess`

export const onDeleteUserApp = `${domain}/onDeleteUserApp`
export const onDeleteUserAppSuccess = `${domain}/onDeleteUserAppSuccess`

export const onBatchOAuthClientIdByAppId = `${domain}/onBatchOAuthClientIdByAppId`

export const onFetchUserApps = `${domain}/onFetchUserApps`
export const onFetchUserAppsSuccess = `${domain}/onFetchUserAppsSuccess`

export const onRegenerateClientSecret = `${domain}/onRegenerateClientSecret`
export const onRegenerateClientSecretSuccess = `${domain}/onRegenerateClientSecretSuccess`

export const onUploadClientThumbnail = `${domain}/onUploadClientThumbnail`

export const onBatchAppUserIds = `${domain}/onBatchAppUserIds`
export const onBatchAppUsersRole = `${domain}/onBatchAppUsersRole`

export const onAddCollaborator = `${domain}/onAddCollaborator`
export const onRemoveCollaborator = `${domain}/onRemoveCollaborator`

export const onInviteCollaborator = `${domain}/onInviteCollaborator`
export const onInviteCollaboratorSuccess = `${domain}/onInviteCollaboratorSuccess`

export const onCreateUserInApp = `${domain}/onCreateUserInApp`
export const onDeleteUserInApp = `${domain}/onDeleteUserInApp`

export const onCreateUserAppData = `${domain}/onCreateUserAppData`
export const onDeleteUserAppData = `${domain}/onDeleteUserAppData`

