export const domain = 'TagsModel';

export const onInit = `${domain}/onInit`;

export const onTagCreate = `${domain}/onTagCreate`;
export const onTagCreateSuccess = `${domain}/onTagCreateSuccess`;

export const onDeleteTag = `${domain}/onDeleteTag`;
export const onDeleteTagSuccess = `${domain}/onDeleteTagSuccess`;

export const onDeleteTagData = `${domain}/onDeleteTagData`;

export const onUpdateTagName = `${domain}/onUpdateTagName`;
export const onUpdateTagColor = `${domain}/onUpdateTagColor`;

export const onTagAttach = `${domain}/onTagAttach`;
export const onTagAttachSuccess = `${domain}/onTagAttachSuccess`;
export const onTagAttachFailure = `${domain}/onTagAttachFailure`;

export const onTagDetach = `${domain}/onTagDetach`;
export const onTagsDetach = `${domain}/onTagsDetach`;
export const onTagDetachSuccess = `${domain}/onTagDetachSuccess`;

export const onTagAssign = `${domain}/onTagAssign`;

export const onBatchTagsData = `${domain}/onBatchTagsData`;
export const onCreateTagData = `${domain}/onCreateTagData`;
export const onUpdateTagData = `${domain}/onUpdateTagData`;

export const onCreateTagsByObjectId = `${domain}/onCreateTagsByObjectId`;
export const onUpdateTagsByObjectId = `${domain}/onUpdateTagsByObjectId`;

export const onBatchTags = `${domain}/onBatchTags`;
