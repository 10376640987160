export const domain = 'ListsModel'

export const onCreateList = `${domain}/onCreateList`
export const onCreateListSuccess = `${domain}/onCreateListSuccess`

export const onCreateListData = `${domain}/onCreateListData`
export const onUpdateListData = `${domain}/onUpdateListData`

export const onUpdateList = `${domain}/onUpdateList`
export const onUpdateListSuccess = `${domain}/onUpdateListSuccess`

export const onMoveList = `${domain}/onMoveList`
export const onUpdateListsOrder = `${domain}/onUpdateListsOrder`

export const onSetCardLimit = `${domain}/onSetCardLimit`
export const onSetCardLimitSuccess = `${domain}/onSetCardLimitSuccess`
export const onUpdateListCardsLimitData = `${domain}/onUpdateListCardsLimitData`
export const onRemoveListCardsLimitData = `${domain}/onRemoveListCardsLimitData`

export const onSetTaskOrdersInList = `${domain}/onSetTaskOrdersInList`

export const onAddUserToTaskListFollowers = `${domain}/onAddUserToTaskListFollowers`
export const onAddUserToTaskListFollowersSuccess = `${domain}/onAddUserToTaskListFollowersSuccess`
export const onAddUserToTaskListFollowersData = `${domain}/onAddUserToTaskListFollowersData`

export const onRemoveUserFromTaskListFollowers = `${domain}/onRemoveUserFromTaskListFollowers`
export const onRemoveUserFromTaskListFollowersSuccess = `${domain}/onRemoveUserFromTaskListFollowersSuccess`
export const onRemoveUserFromTaskListFollowersData = `${domain}/onRemoveUserFromTaskListFollowersData`

export const onCopyList = `${domain}/onCopyList`

export const onMoveAllTasksInList = `${domain}/onMoveAllTasksInList`
export const onMoveAllTasksInListSuccess = `${domain}/onMoveAllTasksInListSuccess`

export const onBatchProjectIdsByListIds = `${domain}/onBatchProjectIdsByListIds`
export const onBatchListIdsByTaskIds = `${domain}/onBatchListIdsByTaskIds`

export const onPurgeTaskListsOrder = `${domain}/onPurgeTaskListsOrder`
