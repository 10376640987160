export const domain = 'FilesModel'

export const onQueueFilesAndStartUploading = `${domain}/onQueueFilesAndStartUploading`

export const onUploadFileSuccess = `${domain}/onUploadFileSuccess`
export const onUploadFileFailure = `${domain}/onUploadFileFailure`
export const onAttachmentTooLarge = `${domain}/onAttachmentTooLarge`

export const onDeleteFile = `${domain}/onDeleteFile`
export const onDeleteFileSuccess = `${domain}/onDeleteFileSuccess`
export const onDeleteFileFailure = `${domain}/onDeleteFileFailure`

export const onCreateFileData = `${domain}/onCreateFileData`
export const onRemoveFileData = `${domain}/onRemoveFileData`
export const onBatchFilesData = `${domain}/onBatchFilesData`

export const onOpenImageGallery = `${domain}/onOpenImageGallery`
export const onCloseImageGallery = `${domain}/onCloseImageGallery`

export const onStartUpload = `${domain}/onStartUpload`
export const onSetUploadProgress = `${domain}/onSetUploadProgress`
export const onFinishUpload = `${domain}/onFinishUpload`
