import React from 'react'
import { useComponent } from './hook'


const CustomerMessagingPlatform: React.FC = () => {
  useComponent()
  return <></>
}

export default CustomerMessagingPlatform
