export const domain = 'PersonalProjectStructureModel'

export const onFetchPersonalProjectStructure = `${domain}/onFetchPersonalProjectStructure`


export const onCreateProjectGroup = `${domain}/onCreateProjectGroup`
export const onCreateProjectGroupSuccess = `${domain}/onCreateProjectGroupSuccess`

export const onUpdateProjectGroup = `${domain}/onUpdateProjectGroup`

export const onDeleteProjectGroup = `${domain}/onDeleteProjectGroup`

export const onMoveProjectInStructure = `${domain}/onMoveProjectInStructure`
export const onMoveProjectGroup = `${domain}/onMoveProjectGroup`

export const onUpdateGrouplessProjectOrders = `${domain}/onUpdateGrouplessProjectOrder`

export const onUpdateProjectOrdersInGroup = `${domain}/onUpdateProjectOrdersInGroup`

export const onBatchProjectGroups = `${domain}/onBatchProjectGroups`
export const onBatchProjectOrdersInGroup = `${domain}/onBatchProjectOrdersInGroup`

export const onCreateProjectGroupData = `${domain}/onCreateProjectGroupData`

export const onUpdateProjectGroupData = `${domain}/onUpdateProjectGroupData`

export const onDeleteProjectGroupData = `${domain}/onDeleteProjectGroupData`

export const onCreatePersonalProjectOrderData = `${domain}/onCreatePersonalProjectOrderData`

export const onUpdatePersonalProjectOrderData = `${domain}/onUpdatePersonalProjectOrderData`

export const onDeletePersonalProjectOrderData = `${domain}/onDeletePersonalProjectOrderData`

export const onCreateProjectInGroupData = `${domain}/onCreateProjectInGroupData`

export const onUpdateProjectInGroupData = `${domain}/onUpdateProjectInGroupData`

export const onDeleteProjectInGroupData = `${domain}/onDeleteProjectInGroupData`