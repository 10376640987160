export const domain = 'UsersStatusModel'

export const onInit = `${domain}/onInit`

export const onBatchUserStatusData = `${domain}/onBatchUserStatusData`
export const onCreateUserStatusData = `${domain}/onCreateUserStatusData`
export const onUpdateUserStatusData = `${domain}/onUpdateUserStatusData`
export const onDeleteUserStatusData = `${domain}/onDeleteUserStatusData`

export const onSetUserStatus = `${domain}/onSetUserStatus`
export const onSetUserStatusSuccess = `${domain}/onSetUserStatusSuccess`

export const onRemoveUserStatus = `${domain}/onRemoveUserStatus`
