export const domain = 'ViewSettingsModel'

export const onCreateViewSettings = `${domain}/onCreateViewSettings`
export const onUpdateViewSettings = `${domain}/onUpdateViewSettings`
export const onUpdateViewSettingsBody = `${domain}/onUpdateViewSettingsBody`

export const onDeleteViewSettings = `${domain}/onDeleteViewSettings`
export const onDeleteViewSettingsSuccess = `${domain}/onDeleteViewSettingsSuccess`

export const onFetchViewSettings = `${domain}/onFetchViewSettings`

export const onCreateBaseViewSettings = `${domain}/onCreateBaseViewSettings`
