export const domain = "CurrentUserModel";

export const onSignIn = `${domain}/onSignIn`;
export const onSignInSuccess = `${domain}/onSignInSuccess`;
export const onSignInFailure = `${domain}/onSignInFailure`;

export const onSignInWithGoogleAccount = `${domain}/onSignInWithGoogleAccount`;
export const onSignInWithGoogleAccountSuccess = `${domain}/onSignInWithGoogleAccountSuccess`;
export const onSignInWithGoogleAccountFailure = `${domain}/onSignInWithGoogleAccountFailure`;

export const onSignOut = `${domain}/onSignOut`;
export const onSignOutSuccess = `${domain}/onSignOutSuccess`;
export const onSignOutFailure = `${domain}/onSignOutFailure`;

export const onAuthenticationDelete = `${domain}/onAuthenticationDelete`;
export const onAuthenticationDeleteSuccess = `${domain}/onAuthenticationDeleteSuccess`;
export const onAuthenticationDeleteFailure = `${domain}/onAuthenticationDeleteFailure`;

export const onSignUp = `${domain}/onSignUp`;
export const onSignUpSuccess = `${domain}/onSignUpSuccess`;
export const onSignUpFailure = `${domain}/onSignUpFailure`;

export const onSignUpWithGoogleAccount = `${domain}/onSignUpWithGoogleAccount`;
export const onSignUpWithGoogleAccountSuccess = `${domain}/onSignUpWithGoogleAccountSuccess`;
export const onSignUpWithGoogleAccountFailure = `${domain}/onSignUpWithGoogleAccountFailure`;

export const onSignInWithAppleAccount = `${domain}/onSignInWithAppleAccount`;
export const onSignInWithAppleAccountSuccess = `${domain}/onSignInWithAppleAccountSuccess`;
export const onSignInWithAppleAccountFailure = `${domain}/onSignInWithAppleAccountFailure`;

export const onSignUpWithAppleAccount = `${domain}/onSignUpWithAppleAccount`;
export const onSignUpWithAppleAccountSuccess = `${domain}/onSignUpWithAppleAccountSuccess`;
export const onSignUpWithAppleAccountFailure = `${domain}/onSignUpWithAppleAccountFailure`;

export const onSendEmailWithPasswordChangeLink = `${domain}/onSendEmailWithPasswordChangeLink`;
export const onSendEmailWithPasswordChangeLinkSuccess = `${domain}/onSendEmailWithPasswordChangeLinkSuccess`;
export const onSendEmailWithPasswordChangeLinkFailure = `${domain}/onSendEmailWithPasswordChangeLinkFailure`;

export const onSetPassword = `${domain}/onSetPassword`;
export const onSetPasswordSuccess = `${domain}/onSetPasswordSuccess`;
export const onSetPasswordFailure = `${domain}/onSetPasswordFailure`;

export const onPasswordChange = `${domain}/onPasswordChange`;
export const onPasswordChangeSuccess = `${domain}/onPasswordChangeSuccess`;
export const onPasswordChangeFailure = `${domain}/onPasswordChangeFailure`;

export const onPasswordChangeActonResultClear = `${domain}/onPasswordChangeActonResultClear`;

export const onResetToInitialState = `${domain}/onResetToInitialState`;

export const onCheckIfEmailExist = `${domain}/onCheckIfEmailExist`;
export const onCheckIfEmailExistSuccess = `${domain}/onCheckIfEmailExistSuccess`;
export const onCheckIfEmailExistFailure = `${domain}/onCheckIfEmailExistFailure`;

export const onInvitedUserSetPassword = `${domain}/onInvitedUserSetPassword`;
export const onInvitedUserSetPasswordSuccess = `${domain}/onInvitedUserSetPasswordSuccess`;
export const onInvitedUserSetPasswordFailure = `${domain}/onInvitedUserSetPasswordFailure`;

export const onDeleteAccount = `${domain}/onDeleteAccount`;

export const onSendUserFeedback = `${domain}/onSendUserFeedback`;
