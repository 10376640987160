export const domain = 'RequestModel'

export const onClearState = `${domain}/onClearState`

export const onSetRequestStatus = `${domain}/onSetRequestStatus`
export const onSetDirtyRequests = `${domain}/onSetDirtyRequests`
export const onSetIsRequestDirty = `${domain}/onSetIsRequestDirty`

export const onFetchProjectViewData = `${domain}/onFetchProjectViewData`
export const onFetchProjectViewDataSuccess = `${domain}/onFetchProjectViewDataSuccess`

export const onFetchConversationViewData = `${domain}/onFetchConversationViewData`
export const onFetchConversationViewDataSuccess = `${domain}/onFetchConversationViewDataSuccess`

export const onFetchSpaceOrConversationViewData = `${domain}/onFetchSpaceOrConversationViewData`

export const onFetchProjectListsData = `${domain}/onFetchProjectListsData`
export const onFetchProjectListsDataSuccess = `${domain}/onFetchProjectListsDataSuccess`

export const onFetchProjectListsAndTasksData = `${domain}/onFetchProjectListsAndTasksData`

export const onFetchProjectTasksData = `${domain}/onFetchProjectTasksData`
export const onFetchProjectTasksDataSuccess = `${domain}/onFetchProjectTasksDataSuccess`

export const onFetchListTasksData = `${domain}/onFetchListTasksData`
export const onFetchListTasksDataSuccess = `${domain}/onFetchListTasksDataSuccess`

export const onFetchUserLatestVisitedTasks = `${domain}/onFetchUserLatestVisitedTasks`
export const onFetchUserLatestVisitedTasksSuccess = `${domain}/onFetchUserLatestVisitedTasksSuccess`
export const onFetchUserLatestVisitedTasksFailure = `${domain}/onFetchUserLatestVisitedTasksFailure`

export const onFetchContainerFilesIfDidNotFetchAlready = `${domain}/onFetchContainerFilesIfDidNotFetchAlready`
export const onFetchContainerFiles = `${domain}/onFetchContainerFiles`

export const onFetchOrganizationTagsOverviewIfDidNotFetchAlready = `${domain}/onFetchOrganizationTagsOverviewIfDidNotFetchAlready`

export const onFetchLatestActiveUserConversations = `${domain}/onFetchLatestActiveUserConversations`
export const onFetchLatestActiveUserConversationsFailure = `${domain}/onFetchLatestActiveUserConversationsFailure`
export const onFetchArchivedProjectsViewData = `${domain}/onFetchArchivedProjectsViewData`

export const onFetchExportedBoardCardsData = `${domain}/onFetchExportedBoardCardsData`

export const onInitDelayedRequests = `${domain}/onInitDelayedRequests`
export const onFetchUserInitialData = `${domain}/onFetchUserInitialData`

export const onSetContainerAccessLossTimestamp = `${domain}/onSetContainerAccessLossTimestamp`

