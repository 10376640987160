export const domain = 'UsersModel'

export const onInit = `${domain}/onInit`
export const onInitSuccess = `${domain}/onInitSuccess`
export const onInitFailure = `${domain}/onInitFailure`

export const currentUserId = `${domain}/currentUserId`

export const onCreateUser = `${domain}/onCreateUser`

export const onUpdateUser = `${domain}/onUpdateUser`
export const onUpdateUserSuccess = `${domain}/onUpdateUserSuccess`
export const onUpdateUserFailure = `${domain}/onUpdateUserFailure`

export const onDeleteUser = `${domain}/onDeleteUser`

export const onBatchUsersData = `${domain}/onBatchUsersData`
export const onBatchUserOnlineStatus = `${domain}/onBatchUserOnlineStatus`

export const onCreateUserData = `${domain}/onCreateUserData`
export const onUpdateUserData = `${domain}/onUpdateUserData`

export const onSetIsUserOnline = `${domain}/onSetIsUserOnline`

export const onFirstNameUpdate = `${domain}/onFirstNameUpdate`
export const onLastNameUpdate = `${domain}/onLastNameUpdate`
export const onNicknameUpdate = `${domain}/onNicknameUpdate`
export const onEmailUpdate = `${domain}/onEmailUpdate`
export const onAvatarUpdate = `${domain}/onAvatarUpdate`
export const onAvatarUpload = `${domain}/onAvatarUpload`
export const onGravatarSet = `${domain}/onGravatarSet`

export const onCreateUserOrganizations = `${domain}/onCreateUserOrganizations`
