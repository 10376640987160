import { ImmutableMap } from 'common/types';
import { Map } from 'immutable';

export interface ModalsState extends ImmutableMap<string, any> {
  modalsData: ModalsData;
  didUserCloseModalUltimately: Map<string, boolean>;
}

export type ModalsData = Map<ModalType, ImmutableMap<string, any>>;

export enum ModalType {
  GUEST_PROFILE_MODAL = 'GUEST_PROFILE_MODAL',
  USER_STATUS_MODAL = 'USER_STATUS_MODAL',
  POST_INVITATION_MODAL = 'POST_INVITATION_MODAL',
  EDIT_APP_MODAL = 'EDIT_APP_MODAL',
  RESEND_INVITATION_MODAL = 'RESEND_INVITATION_MODAL',
  MANAGE_OVERWATCH_MODAL = 'MANAGE_OVERWATCH_MODAL',
  MOBILE_IMAGE_VIEW_MODAL = 'MOBILE_IMAGE_VIEW_MODAL',
  OVERWATCH_PERFORMANCE_INFO_MODAL = 'OVERWATCH_PERFORMANCE_INFO_MODAL',
  MY_TASKS_PERFORMANCE_INFO_MODAL = 'MY_TASKS_PERFORMANCE_INFO_MODAL',
  CLIENT_NEW_VERSION_MODAL = 'CLIENT_NEW_VERSION_MODAL',
  ADD_NEW_CARD_MODAL = 'ADD_NEW_CARD_MODAL',
  ADD_NEW_PROJECT_MODAL = 'ADD_NEW_PROJECT_MODAL',
  ADD_FIRST_PROJECT_MODAL = 'ADD_FIRST_PROJECT_MODAL',
  HEYSPACE_MEET = 'HEYSPACE_MEET',
  INVITE_PEOPLE_MODAL = 'INVITE_PEOPLE_MODAL',
  TIMECAMP_API_KEY_MODAL = 'TIMECAMP_API_KEY_MODAL',
  BROWSER_SPACES_MODAL = 'BROWSER_SPACES_MODAL',
  GLOBAL_SEARCH_MODAL = 'GLOBAL_SEARCH_MODAL',
}
