import { ExtensionNamespace } from '../../ExtensionsModel/types';
import { SubscriptionPlanId } from '../../SubscriptionModel/types';

export default {
  [SubscriptionPlanId.BASIC]: {
    singleUploadLimit: 5242880, // 5 MB
    extensionPermissions: {
      [ExtensionNamespace.AGILE]: false,
      [ExtensionNamespace.CALENDAR_SYNC]: false,
      [ExtensionNamespace.GOOGLE_DRIVE_PICKER]: false,
      [ExtensionNamespace.DROPBOX_CHOOSER]: false,
      [ExtensionNamespace.INTERNAL_CALENDAR]: false,
      [ExtensionNamespace.TASK_RECURRENCE]: false,
      [ExtensionNamespace.OVERWATCH]: false,
      [ExtensionNamespace.MY_TASKS]: false,
      [ExtensionNamespace.TIMELINE]: false,
      [ExtensionNamespace.SPACE_GROUPS]: false,
      [ExtensionNamespace.SPACE_TASK_LIST]: false,
      [ExtensionNamespace.HEYSPACE_MEETS]: false,
      [ExtensionNamespace.KANBAN_LIMITS]: false,
      [ExtensionNamespace.FILE_LIST]: false,
      [ExtensionNamespace.SCHEDULE]: false,
    },
    hasAccessToTags: false,
    userPerWorkspaceLimit: 5,
  },
  [SubscriptionPlanId.PRO_MONTHLY]: {
    singleUploadLimit: null,
    extensionPermissions: {
      [ExtensionNamespace.AGILE]: true,
      [ExtensionNamespace.CALENDAR_SYNC]: true,
      [ExtensionNamespace.GOOGLE_DRIVE_PICKER]: true,
      [ExtensionNamespace.DROPBOX_CHOOSER]: true,
      [ExtensionNamespace.INTERNAL_CALENDAR]: true,
      [ExtensionNamespace.TASK_RECURRENCE]: true,
      [ExtensionNamespace.OVERWATCH]: true,
      [ExtensionNamespace.MY_TASKS]: true,
      [ExtensionNamespace.TIMELINE]: true,
      [ExtensionNamespace.SPACE_GROUPS]: true,
      [ExtensionNamespace.SPACE_TASK_LIST]: true,
      [ExtensionNamespace.HEYSPACE_MEETS]: true,
      [ExtensionNamespace.KANBAN_LIMITS]: true,
      [ExtensionNamespace.FILE_LIST]: true,
      [ExtensionNamespace.SCHEDULE]: true,
    },
    hasAccessToTags: true,
    userPerWorkspaceLimit: 0,
  },
  [SubscriptionPlanId.PRO_YEARLY]: {
    singleUploadLimit: null,
    extensionPermissions: {
      [ExtensionNamespace.AGILE]: true,
      [ExtensionNamespace.CALENDAR_SYNC]: true,
      [ExtensionNamespace.GOOGLE_DRIVE_PICKER]: true,
      [ExtensionNamespace.DROPBOX_CHOOSER]: true,
      [ExtensionNamespace.INTERNAL_CALENDAR]: true,
      [ExtensionNamespace.TASK_RECURRENCE]: true,
      [ExtensionNamespace.OVERWATCH]: true,
      [ExtensionNamespace.MY_TASKS]: true,
      [ExtensionNamespace.TIMELINE]: true,
      [ExtensionNamespace.SPACE_GROUPS]: true,
      [ExtensionNamespace.SPACE_TASK_LIST]: true,
      [ExtensionNamespace.HEYSPACE_MEETS]: true,
      [ExtensionNamespace.KANBAN_LIMITS]: true,
      [ExtensionNamespace.FILE_LIST]: true,
      [ExtensionNamespace.SCHEDULE]: true,
    },
    hasAccessToTags: true,
    userPerWorkspaceLimit: 0,
  },
};
