export const domain = 'NotificationsModel'

export const onInit = `${domain}/onInit`

export const onCreateNotificationData = `${domain}/onCreateNotificationData`
export const onUpdateNotificationData = `${domain}/onUpdateNotificationData`
export const onBatchNotificationsData = `${domain}/onBatchNotificationsData`

export const onSetIsNotificationArchived = `${domain}/onSetIsNotificationArchived`
export const onSetIsNotificationArchivedSuccess = `${domain}/onSetIsNotificationArchivedSuccess`

export const onFetchNotifications = `${domain}/onFetchNotifications`

export const onFetchUnreadNotifications = `${domain}/onFetchUnreadNotifications`
export const onFetchUnreadNotificationsSuccess = `${domain}/onFetchUnreadNotificationsSuccess`

export const onMutePushNotifications = `${domain}/onMutePushNotifications`

export const onUnmutePushNotifications = `${domain}/onUnmutePushNotifications`

export const onSetCurrentDeviceId = `${domain}/onSetCurrentDeviceId`

export const onGetCurrentDeviceId = `${domain}/onGetCurrentDeviceId`

export const onResetUnreadNotifications = `${domain}/onResetUnreadNotifications`
export const onResetUnreadNotificationsSuccess = `${domain}/onResetUnreadNotificationsSuccess`

export const onMarkAllAsRead = `${domain}/onMarkAllAsRead`
export const onMarkAllAsReadSuccess = `${domain}/onMarkAllAsReadSuccess`

export const onReadNotification = `${domain}/onReadNotification`
export const onReadNotificationSuccess = `${domain}/onReadNotificationSuccess`

export const onUpdateNotificationSettings = `${domain}/onUpdateNotificationSettings`

export const onDeleteNotificationsSettingsAlert = `${domain}/onDeleteNotificationsSettingsAlert`

export const onCreateNotificationsSettingsData = `${domain}/onCreateNotificationsSettingsData`

export const onSetUnreadNotificationsDisplay = `${domain}/onSetUnreadNotificationsDisplay`

export const onSetChatNotificationSettingsType = `${domain}/onSetChatNotificationSettingsType`
export const onSetChatNotificationSettingsTypeSuccess = `${domain}/onSetChatNotificationSettingsTypeSuccess`
export const onUpdateChatNotificationSettings = `${domain}/onUpdateChatNotificationSettings`

export const onFetchChatNotificationSettingsTypes = `${domain}/onFetchChatNotificationSettingsTypes`
export const onFetchChatNotificationSettingsTypesSuccess = `${domain}/onFetchChatNotificationSettingsTypesSuccess`

export const onFetchNotificationSettings = `${domain}/onFetchNotificationSettings`

export const onArchiveUserInOrganizationNotifications = `${domain}/onArchiveUserInOrganizationNotifications`
export const onArchiveUserInOrganizationNotificationsSuccess = `${domain}/onArchiveUserInOrganizationNotificationsSuccess`

export const onFetchUserLatestNotificationCenterDisplays = `${domain}/onFetchUserLatestNotificationCenterDisplays`
export const onSetUserLatestNotificationCenterDisplay = `${domain}/onSetUserLatestNotificationCenterDisplay`
export const onBatchUserLatestNotificationCenterDisplay = `${domain}/onBatchUserLatestNotificationCenterDisplay`
