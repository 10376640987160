export const domain = 'TaskRecurrenceModel'


export const onSetTaskRecurrence = `${domain}/onSetTaskRecurrence`
export const onSetTaskRecurrenceSuccess = `${domain}/onSetTaskRecurrenceSuccess`

export const onDeleteTaskRecurrenceSettings = `${domain}/onDeleteTaskRecurrenceSettings`
export const onDeleteTaskRecurrenceSettingsSuccess = `${domain}/onDeleteTaskRecurrenceSettingsSuccess`

export const onUpdateTaskRecurrenceSettingsData = `${domain}/onUpdateTaskRecurrenceSettingsData`
export const onDeleteTaskRecurrenceSettingsData = `${domain}/onDeleteTaskRecurrenceSettingsData`

export const onUpdateTaskRecurrenceDependencyData = `${domain}/onUpdateTaskRecurrenceDependencyData`
export const onDeleteTaskRecurrenceDependencyData = `${domain}/onDeleteTaskRecurrenceDependencyData`
export const onBatchRecurrenceDedependency = `${domain}/onBatchRecurrenceDedependency`





