export const domain = 'TaskTimeEstimatesModel';

export const onFetchTaskTimeEstimate = `${domain}/onFetchTaskTimeEstimate`;
export const onFetchTaskTimeEstimateSuccess = `${domain}/onFetchTaskTimeEstimateSuccess`;

export const onCreateTaskTimeEstimate = `${domain}/onCreateTaskTimeEstimate`;
export const onCreateTaskTimeEstimateSuccess = `${domain}/onCreateTaskTimeEstimateSuccess`;

export const onUpdateTaskTimeEstimate = `${domain}/onUpdateTaskTimeEstimate`;
export const onUpdateTaskTimeEstimateSuccess = `${domain}/onUpdateTaskTimeEstimateSuccess`;

export const onDeleteTaskTimeEstimate = `${domain}/onDeleteTaskTimeEstimate`;
export const onDeleteTaskTimeEstimateSuccess = `${domain}/onDeleteTaskTimeEstimateSuccess`;
