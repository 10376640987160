export const domain = 'ChecklistsModel'


export const onAddItemToChecklist = `${domain}/onAddItemToChecklist`
export const onAddItemToChecklistSuccess = `${domain}/onAddItemToChecklistSuccess`
export const onAddItemToChecklistFailure = `${domain}/onAddItemToChecklistFailure`

export const onRemoveItemFromChecklist = `${domain}/onRemoveItemFromChecklist`
export const onRemoveItemFromChecklistSuccess = `${domain}/onRemoveItemFromChecklistSuccess`
export const onRemoveItemFromChecklistFailure = `${domain}/onRemoveItemFromChecklistFailure`

export const onChangeChecklistItemData = `${domain}/onChangeChecklistItemData`
export const onChangeChecklistItemDataSuccess = `${domain}/onChangeChecklistItemDataSuccess`
export const onChangeChecklistItemDataFailure = `${domain}/onChangeChecklistItemDataFailure`

export const onChangeChecklistItemText = `${domain}/onChangeChecklistItemText`

export const onNewChecklistItemSeen = `${domain}/onNewChecklistItemSeen`

export const onUpdateChecklistItemData = `${domain}/onUpdateChecklistItemData`
export const onUpdateChecklistItemDataSuccess = `${domain}/onUpdateChecklistItemDataSuccess`
export const onBatchChecklistItemsData = `${domain}/onBatchChecklistItemsData`

export const onUpdateChecklistItemsOrder = `${domain}/onUpdateChecklistItemsOrder`

export const onMoveChecklistItem = `${domain}/onMoveChecklistItem`

export const onConvertChecklistItemToCard = `${domain}/onConvertChecklistItemToCard`

export const onBatchChecklistItemsAssignees = `${domain}/onBatchChecklistItemsAssignees`
export const onCreateChecklistItemAssignee = `${domain}/onCreateChecklistItemAssignee`
export const onDeleteChecklistItemAssignee = `${domain}/onDeleteChecklistItemAssignee`
export const onAddChecklistItemAssignee = `${domain}/onAddChecklistItemAssignee`
export const onRemoveChecklistItemAssignee = `${domain}/onRemoveChecklistItemAssignee`
export const onCreateUserAndAddToSubscribers = `${domain}/onCreateUserAndAddToSubscribers`
export const onUpdateChecklistItemAssignees = `${domain}/onUpdateChecklistItemAssignees`
