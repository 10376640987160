import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import userPermissionsBySubscriptionPlan from './constants/userPermissionsBySubscriptionPlan';

import {
  selectCurrentSubscriptionPlanCode,
  selectCurrentSubscriptionSeatCount,
  selectIsCurrentSubscriptionActive,
  selectIsCurrentSubscriptionFree,
} from '../SubscriptionModel/selectors';
import { selectCurrentOrganizationMemberUserIds } from '../OrganizationsModel/selectors';

import {
  selectSubscriptionViewRequestStatus,
  selectOrganizationGuestsViewRequestStatus,
} from '../RequestModel/selectors';
import { RequestStatus } from '../RequestModel/types';
import { ADDITIONAL_GUEST_SEATS } from './constants';
import generateSelectorName from '../../../utils/generateSelectorName';
import { selectCurrentOrganizationOccupiedGuestSeatsCount } from '../GuestsModel/selectors';

export const selectSingleFileUploadLimit = createSelector(selectCurrentSubscriptionPlanCode, (planId) => {
  const permissions = userPermissionsBySubscriptionPlan[planId];
  return permissions && permissions.singleUploadLimit;
});

/**
 * @param extensionNamespace
 */
export const selectHasAccessToExtension = createCachedSelector(
  selectCurrentSubscriptionPlanCode,
  (_, args) => args.extensionNamespace,
  (planCode, extensionNamespace) => {
    const planPermissions = userPermissionsBySubscriptionPlan[planCode];
    if (!planPermissions) {
      return false;
    }
    const { extensionPermissions } = planPermissions;
    if (typeof extensionPermissions[extensionNamespace] === 'undefined') {
      return true;
    }

    return extensionPermissions[extensionNamespace] as boolean;
  }
)((_, args) => generateSelectorName(args, ['extensionNamespace']));

export const selectHasAccessToTags = createSelector(selectCurrentSubscriptionPlanCode, (planCode) => {
  const permissions = userPermissionsBySubscriptionPlan[planCode];
  return Boolean(permissions && permissions.hasAccessToTags);
});

export const selectUsersPerWorkspaceLimit = createSelector(
  selectCurrentSubscriptionPlanCode,
  selectCurrentSubscriptionSeatCount,
  selectIsCurrentSubscriptionActive,
  (planCode, currentSubscriptionSeatCount, isActive) => {
    if (isNil(currentSubscriptionSeatCount) || !isActive) {
      return get(userPermissionsBySubscriptionPlan, `${planCode}.userPerWorkspaceLimit`, 0);
    } else {
      return currentSubscriptionSeatCount;
    }
  }
);

// args: usersToInviteCount
export const selectCanUsersBeInvitedToWorkspace = createSelector(
  selectUsersPerWorkspaceLimit,
  selectCurrentOrganizationMemberUserIds,
  (_, args) => args.usersToInviteCount,
  (userPerWorkspaceLimit, organizationUserIds, usersToInviteCount) =>
    !(userPerWorkspaceLimit && organizationUserIds.size + usersToInviteCount > userPerWorkspaceLimit)
);

export const selectCurrentSubscriptionGuestSeatCount = createSelector(
  selectUsersPerWorkspaceLimit,
  selectIsCurrentSubscriptionFree,
  (seatCount, isFree) => (isFree ? ADDITIONAL_GUEST_SEATS : seatCount + ADDITIONAL_GUEST_SEATS)
);

export const selectIsWorkspaceGuestSeatsAvailable = createSelector(
  selectCurrentSubscriptionGuestSeatCount,
  selectCurrentOrganizationOccupiedGuestSeatsCount,
  (guestSeatCount, currentOrganizationOccupiedGuestSeatCount) =>
    Boolean(guestSeatCount && currentOrganizationOccupiedGuestSeatCount < guestSeatCount)
);

// args: guestsToInviteCount
export const selectCanGuestsBeInvitedToWorkspace = createSelector(
  selectCurrentSubscriptionGuestSeatCount,
  selectCurrentOrganizationOccupiedGuestSeatsCount,
  (_, args) => args.guestsToInviteCount,
  (guestSeatCount, occupiedGuestSeatCount, guestsToInviteCount) =>
    occupiedGuestSeatCount + guestsToInviteCount <= guestSeatCount
);

export const selectIsWorkspaceMembersValidForSubscription = createSelector(
  selectUsersPerWorkspaceLimit,
  selectCurrentOrganizationMemberUserIds,
  selectCurrentSubscriptionGuestSeatCount,
  selectCurrentOrganizationOccupiedGuestSeatsCount,
  selectSubscriptionViewRequestStatus,
  selectOrganizationGuestsViewRequestStatus,
  (
    usersPerWorkspaceLimit,
    organizationUserIds,
    guestSeatCount,
    occupiedGuestSeatCount,
    requestSubscriptionViewStatus,
    requestOrganizationGuestsViewStatus
  ) => {
    const isSubscriptionViewLoaded = requestSubscriptionViewStatus === RequestStatus.SUCCESS;
    const isOrganizationGuestsViewLoaded = requestOrganizationGuestsViewStatus === RequestStatus.SUCCESS;

    if (isSubscriptionViewLoaded && isOrganizationGuestsViewLoaded) {
      const isMembersExceeded = usersPerWorkspaceLimit < organizationUserIds.size;
      const isGuestsexceeded = guestSeatCount < occupiedGuestSeatCount;
      return !isMembersExceeded && !isGuestsexceeded;
    } else {
      return true;
    }
  }
);

export const selectExcessWorkspaceMembersForSubscription = createSelector(
  selectIsWorkspaceMembersValidForSubscription,
  selectUsersPerWorkspaceLimit,
  selectCurrentOrganizationMemberUserIds,
  selectCurrentSubscriptionGuestSeatCount,
  selectCurrentOrganizationOccupiedGuestSeatsCount,
  (isWorkspaceMembersValid, usersPerWorkspaceLimit, organizationUserIds, guestSeatCount, occupiedGuestSeatCount) => {
    if (isWorkspaceMembersValid) {
      return 0;
    } else {
      const exceessMembers = organizationUserIds.size - usersPerWorkspaceLimit;
      const exceessGuests = occupiedGuestSeatCount - guestSeatCount;
      let excessUsers = 0;
      if (exceessMembers > 0) {
        excessUsers += exceessMembers;
      }
      if (exceessGuests > 0) {
        excessUsers += exceessGuests;
      }
      return excessUsers;
    }
  }
);
