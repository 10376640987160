export const domain = 'Timecamp';

export const onFetchHasTimecampIntegration = `${domain}/onHasIntegration`;
export const onFetchHasTimecampIntegrationSuccess = `${domain}/onFetchHasIntegrationSuccess`;

export const onSaveToken = `${domain}/onSaveToken`;
export const onSaveTokenSuccess = `${domain}/onSaveTokenSuccess`;

export const onDeleteToken = `${domain}/onDeleteToken`;
export const onDeleteTokenSuccess = `${domain}/onDeleteTokenSuccess`;

export const onSetTimer = `${domain}/onSetTimer`;
export const onSetTimerSuccess = `${domain}/onSetTimerSuccess`;

export const onAddTimeEntry = `${domain}/onAddTimeEntry`;
export const onAddTimeEntrySuccess = `${domain}/onAddEntryTimeSuccess`;

export const onCheckTimerStatus = `${domain}/onCheckTimerStatus`;
export const onCheckTimerStatusSuccess = `${domain}/onCheckTimerStatusSuccess`;

export const onFetchTaskTimeEntries = `${domain}/onFetchTaskTimeEntries`;
export const onFetchTaskTimeEntriesSuccess = `${domain}/onFetchTaskTimeEntriesSuccess`;

export const onAddTaskTime = `${domain}/onUpdateTaskTime`;
