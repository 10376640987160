export const domain = 'SubscriptionModel'

export const onInit = `${domain}/onInit`

export const onCheckoutStageCheck = `${domain}/onCheckoutStageCheck`
export const onCheckoutStageCheckSuccess = `${domain}/onCheckoutStageCheckSuccess`
export const onCheckoutStageCheckFailure = `${domain}/onCheckoutStageCheckFailure`

export const onSetCheckoutStage = `${domain}/onSetCheckoutStage`
export const onSetPaymentPage = `${domain}/onSetPaymentPage`
export const onFetchPaymentPage = `${domain}/onFetchPaymentPage`
export const onRemovePersistPaymentPage = `${domain}/onRemovePersistPaymentPage`

export const onSelectPlan = `${domain}/onSelectPlan`

export const onAdvanceFromSubscriptionChoice = `${domain}/onAdvanceFromSubscriptionChoice`
export const onAdvanceFromSubscriptionDetails = `${domain}/onAdvanceFromSubscriptionDetails`

export const onChooseBillingPeriod = `${domain}/onChooseBillingPeriod`
export const onSeatsNumberUpdate = `${domain}/onSeatsNumberUpdate`

export const onClientShouldBeValidated = `${domain}/onClientShouldBeValidated`
export const onClientNameUpdate = `${domain}/onClientNameUpdate`
export const onClientStreetUpdate = `${domain}/onClientStreetUpdate`
export const onClientCityUpdate = `${domain}/onClientCityUpdate`
export const onClientZipUpdate = `${domain}/onClientZipUpdate`
export const onClientProvinceUpdate = `${domain}/onClientProvinceUpdate`
export const onClientCountryUpdate = `${domain}/onClientCountryUpdate`

export const onPaymentSuccess = `${domain}/onPaymentSuccess`

export const onFinishCheckout = `${domain}/onFinishCheckout`
export const onFinishCheckoutSuccess = `${domain}/onFinishCheckoutSuccess`
export const onFinishCheckoutFailure = `${domain}/onFinishCheckoutFailure`

export const onCreateSubscriptionData = `${domain}/onCreateSubscriptionData`
export const onUpdateSubscriptionData = `${domain}/onUpdateSubscriptionData`
export const onRemoveSubscriptionData = `${domain}/onRemoveSubscriptionData`
export const onBatchSubscriptionData = `${domain}/onBatchSubscriptionData`

export const onCancelOrganizationSubscription = `${domain}/onCancelOrganizationSubscription`

export const onSetSubscriptionCancellationStatus = `${domain}/onSetSubscriptionCancellationStatus`

export const onRemoveSubscriptionCancellationStatus = `${domain}/onRemoveSubscriptionCancellationStatus`

export const onCreatePlanData = `${domain}/onCreatePlanData`
export const onUpdatePlanData = `${domain}/onUpdatePlanData`
export const onRemovePlanData = `${domain}/onRemovePlanData`

export const onCreateInvoiceData = `${domain}/onCreateInvoiceData`
export const onUpdateInvoiceData = `${domain}/onUpdateInvoiceData`
export const onRemoveInvoiceData = `${domain}/onRemoveInvoiceData`

export const onFetchSubscriptionData = `${domain}/onFetchSubscriptionData`
export const onFetchSubscriptionDataSuccess = `${domain}/onFetchSubscriptionDataSuccess`
export const onFetchSubscriptionDataFailure = `${domain}/onFetchSubscriptionDataFailure`

export const onFetchSubscriptionInvoices = `${domain}/onFetchSubscriptionInvoices`
export const onFetchSubscriptionInvoicesSuccess = `${domain}/onFetchSubscriptionInvoicesSuccess`
export const onFetchSubscriptionInvoicesFailure = `${domain}/onFetchSubscriptionInvoicesFailure`

export const onUpdateSubscription = `${domain}/onUpdateSubscription`
export const onUpdateCustomSubscription = `${domain}/onUpdateCustomSubscription`

export const onDownloadInvoice = `${domain}/onDownloadInvoice`

export const onFetchUserOrganizationSubscriptions = `${domain}/onFetchUserOrganizationSubscriptions`

export const onUpdateSubscriptionCouponsData = `${domain}/onUpdateSubscriptionCouponsData`
