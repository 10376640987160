// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MRJ2Fe3VdRIfPeZwG88l{
color:#1584fc;
}
.sQxlIjErf9vpi1N2EVi5{
color:#57bec7;
}
.fJUxp8xrNLTVJ0tOA4q6{
color:#f8c233;
}
.wewg4NfmPzaNCZopGnQQ{
color:#e94149;
}
.izh_JLEYEnAwwtoiLngW{
color:#f79dd0;
}
.v1dRE88RSKk3KiDNXpMm{
color:#67b868;
}
.Care7Yt2D1h2CZjZktyl{
color:#5ccff5;
}
.ghIiJRNGgDr4jaFrmNog{
color:#7850fc;
}
.nAsHCm29tbzGLyyVrg9B{
color:#e9579e;
}
.UshDh54a4bqJ_5FPZsOw{
color:#b9c3ce;
}
._BIaUtisIineZzpxAXL0{
color:#2d80eb;
}
.YIbLnnZfanMPK8XFjDR9{
color:#ff5c73;
}
.fgbB6AdkHmoZz10d5wNj{
color:#f9f9f9;
}
.tzACBanFLFxOYM4_NJzL{
  color:#b9c3ce;
}
.tzACBanFLFxOYM4_NJzL.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.tzACBanFLFxOYM4_NJzL:focus{
    color:#b9c3ce
  }
.tzACBanFLFxOYM4_NJzL:hover{
   color:#1584fc
  }
.tzACBanFLFxOYM4_NJzL.KoJUvuQ1CagYe76zvfm2, .tzACBanFLFxOYM4_NJzL:active, .tzACBanFLFxOYM4_NJzL.Fxp1o7CbM_kSaxjBogmy{
    color:#1584fc !important;
  }
.oWxDDAKxyO9GKsGRLbT3{
  color:#b9c3ce;
}
.oWxDDAKxyO9GKsGRLbT3.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.oWxDDAKxyO9GKsGRLbT3:focus{
    color:#b9c3ce
  }
.oWxDDAKxyO9GKsGRLbT3:hover{
   color:#57bec7
  }
.oWxDDAKxyO9GKsGRLbT3.KoJUvuQ1CagYe76zvfm2, .oWxDDAKxyO9GKsGRLbT3:active, .oWxDDAKxyO9GKsGRLbT3.Fxp1o7CbM_kSaxjBogmy{
    color:#57bec7 !important;
  }
.gAAQA1ht4u4M9vsJEMyI{
  color:#b9c3ce;
}
.gAAQA1ht4u4M9vsJEMyI.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.gAAQA1ht4u4M9vsJEMyI:focus{
    color:#b9c3ce
  }
.gAAQA1ht4u4M9vsJEMyI:hover{
   color:#f8c233
  }
.gAAQA1ht4u4M9vsJEMyI.KoJUvuQ1CagYe76zvfm2, .gAAQA1ht4u4M9vsJEMyI:active, .gAAQA1ht4u4M9vsJEMyI.Fxp1o7CbM_kSaxjBogmy{
    color:#f8c233 !important;
  }
.ysGMUHgSH4kSXGYkhP79{
  color:#b9c3ce;
}
.ysGMUHgSH4kSXGYkhP79.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.ysGMUHgSH4kSXGYkhP79:focus{
    color:#b9c3ce
  }
.ysGMUHgSH4kSXGYkhP79:hover{
   color:#e94149
  }
.ysGMUHgSH4kSXGYkhP79.KoJUvuQ1CagYe76zvfm2, .ysGMUHgSH4kSXGYkhP79:active, .ysGMUHgSH4kSXGYkhP79.Fxp1o7CbM_kSaxjBogmy{
    color:#e94149 !important;
  }
.EgsemUYJs8CYJ_v_PEHO{
  color:#b9c3ce;
}
.EgsemUYJs8CYJ_v_PEHO.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.EgsemUYJs8CYJ_v_PEHO:focus{
    color:#b9c3ce
  }
.EgsemUYJs8CYJ_v_PEHO:hover{
   color:#f79dd0
  }
.EgsemUYJs8CYJ_v_PEHO.KoJUvuQ1CagYe76zvfm2, .EgsemUYJs8CYJ_v_PEHO:active, .EgsemUYJs8CYJ_v_PEHO.Fxp1o7CbM_kSaxjBogmy{
    color:#f79dd0 !important;
  }
.PfvgsRGCVmCYMgzb3kOV{
  color:#b9c3ce;
}
.PfvgsRGCVmCYMgzb3kOV.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.PfvgsRGCVmCYMgzb3kOV:focus{
    color:#b9c3ce
  }
.PfvgsRGCVmCYMgzb3kOV:hover{
   color:#67b868
  }
.PfvgsRGCVmCYMgzb3kOV.KoJUvuQ1CagYe76zvfm2, .PfvgsRGCVmCYMgzb3kOV:active, .PfvgsRGCVmCYMgzb3kOV.Fxp1o7CbM_kSaxjBogmy{
    color:#67b868 !important;
  }
.wqBHW0juySCCbnWaEnfU{
  color:#b9c3ce;
}
.wqBHW0juySCCbnWaEnfU.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.wqBHW0juySCCbnWaEnfU:focus{
    color:#b9c3ce
  }
.wqBHW0juySCCbnWaEnfU:hover{
   color:#5ccff5
  }
.wqBHW0juySCCbnWaEnfU.KoJUvuQ1CagYe76zvfm2, .wqBHW0juySCCbnWaEnfU:active, .wqBHW0juySCCbnWaEnfU.Fxp1o7CbM_kSaxjBogmy{
    color:#5ccff5 !important;
  }
.v1dSwY445iBmfcVT6CIM{
  color:#b9c3ce;
}
.v1dSwY445iBmfcVT6CIM.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.v1dSwY445iBmfcVT6CIM:focus{
    color:#b9c3ce
  }
.v1dSwY445iBmfcVT6CIM:hover{
   color:#7850fc
  }
.v1dSwY445iBmfcVT6CIM.KoJUvuQ1CagYe76zvfm2, .v1dSwY445iBmfcVT6CIM:active, .v1dSwY445iBmfcVT6CIM.Fxp1o7CbM_kSaxjBogmy{
    color:#7850fc !important;
  }
.W39QHkPLcNkKME31SlEN{
  color:#b9c3ce;
}
.W39QHkPLcNkKME31SlEN.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.W39QHkPLcNkKME31SlEN:focus{
    color:#b9c3ce
  }
.W39QHkPLcNkKME31SlEN:hover{
   color:#e9579e
  }
.W39QHkPLcNkKME31SlEN.KoJUvuQ1CagYe76zvfm2, .W39QHkPLcNkKME31SlEN:active, .W39QHkPLcNkKME31SlEN.Fxp1o7CbM_kSaxjBogmy{
    color:#e9579e !important;
  }
.iuHFLCNLqyx6IL9NryLh{
  color:#b9c3ce;
}
.iuHFLCNLqyx6IL9NryLh.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.iuHFLCNLqyx6IL9NryLh:focus{
    color:#b9c3ce
  }
.iuHFLCNLqyx6IL9NryLh:hover{
   color:#b9c3ce
  }
.iuHFLCNLqyx6IL9NryLh.KoJUvuQ1CagYe76zvfm2, .iuHFLCNLqyx6IL9NryLh:active, .iuHFLCNLqyx6IL9NryLh.Fxp1o7CbM_kSaxjBogmy{
    color:#b9c3ce !important;
  }
.fUx9vXkkpvv8m2y57VOT{
  color:#b9c3ce;
}
.fUx9vXkkpvv8m2y57VOT.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.fUx9vXkkpvv8m2y57VOT:focus{
    color:#b9c3ce
  }
.fUx9vXkkpvv8m2y57VOT:hover{
   color:#2d80eb
  }
.fUx9vXkkpvv8m2y57VOT.KoJUvuQ1CagYe76zvfm2, .fUx9vXkkpvv8m2y57VOT:active, .fUx9vXkkpvv8m2y57VOT.Fxp1o7CbM_kSaxjBogmy{
    color:#2d80eb !important;
  }
.f_M1iCAcCDbMBBippsDg{
  color:#b9c3ce;
}
.f_M1iCAcCDbMBBippsDg.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.f_M1iCAcCDbMBBippsDg:focus{
    color:#b9c3ce
  }
.f_M1iCAcCDbMBBippsDg:hover{
   color:#ff5c73
  }
.f_M1iCAcCDbMBBippsDg.KoJUvuQ1CagYe76zvfm2, .f_M1iCAcCDbMBBippsDg:active, .f_M1iCAcCDbMBBippsDg.Fxp1o7CbM_kSaxjBogmy{
    color:#ff5c73 !important;
  }
.ava2G_YFsiR5tcjmwnRT{
  color:#b9c3ce;
}
.ava2G_YFsiR5tcjmwnRT.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.ava2G_YFsiR5tcjmwnRT:focus{
    color:#b9c3ce
  }
.ava2G_YFsiR5tcjmwnRT:hover{
   color:#f9f9f9
  }
.ava2G_YFsiR5tcjmwnRT.KoJUvuQ1CagYe76zvfm2, .ava2G_YFsiR5tcjmwnRT:active, .ava2G_YFsiR5tcjmwnRT.Fxp1o7CbM_kSaxjBogmy{
    color:#f9f9f9 !important;
  }
.bVZ3mursm0TNqum4c_lV{
  color:#b9c3ce;
}
.bVZ3mursm0TNqum4c_lV.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.bVZ3mursm0TNqum4c_lV:focus{
    color:#b9c3ce
  }
.bVZ3mursm0TNqum4c_lV:hover{
   color:#ffffff
  }
.bVZ3mursm0TNqum4c_lV.KoJUvuQ1CagYe76zvfm2, .bVZ3mursm0TNqum4c_lV:active, .bVZ3mursm0TNqum4c_lV.Fxp1o7CbM_kSaxjBogmy{
    color:#ffffff !important;
  }
.uFwutwoejoJ2po6v28dA{
  color:#b9c3ce;
}
.uFwutwoejoJ2po6v28dA.TXtD7pfqzBZsanVNeEp7{
    background:rgba(185, 195, 206, 0.2);
    color:#b9c3ce;
  }
.uFwutwoejoJ2po6v28dA:focus{
    color:#b9c3ce
  }
.uFwutwoejoJ2po6v28dA:hover{
   color:#f8f9f9
  }
.uFwutwoejoJ2po6v28dA.KoJUvuQ1CagYe76zvfm2, .uFwutwoejoJ2po6v28dA:active, .uFwutwoejoJ2po6v28dA.Fxp1o7CbM_kSaxjBogmy{
    color:#f8f9f9 !important;
  }
.APSmALQ2XmNsxllcMqHC{
background:#1584fc;
}
.BfVZ7Hl8fk6BNXepjIg_{
background:#57bec7;
}
.ucFoYIwKURB3gE6NUemU{
background:#f8c233;
}
.QxpUPv5J9wuWoKLUH9h_{
background:#e94149;
}
.ekG0acjZG0d1IMaPNtWg{
background:#f79dd0;
}
.PVP9QOPXxB8KjPP7WlHd{
background:#67b868;
}
.bGskY1p8Zrr59saFBiYM{
background:#5ccff5;
}
.ao48kJvcoOCdjXyw_1x7{
background:#7850fc;
}
.T8Lvej91DHjZJwHRpR_u{
background:#e9579e;
}
.pArzHfL3eN7bbQf2AQLI{
background:#b9c3ce;
}
.zMGbUWp7MlJkSmeoqoXg{
background:#2d80eb;
}
.zlZNcaXSz8WlOlSPg6FR{
background:#ff5c73;
}
.OXp1sYNrggU8mXoPYlIw{
background:#f9f9f9;
}
.BOH33dvnz0xVI4KWjXgR:hover, .BOH33dvnz0xVI4KWjXgR:active, .BOH33dvnz0xVI4KWjXgR.KoJUvuQ1CagYe76zvfm2{
    color:#1584fc;
  }
.AXspO9CWOm0NzN59GUbg:hover, .AXspO9CWOm0NzN59GUbg:active, .AXspO9CWOm0NzN59GUbg.KoJUvuQ1CagYe76zvfm2{
    color:#57bec7;
  }
.ul5PpKahXiZeyE1oz8hj:hover, .ul5PpKahXiZeyE1oz8hj:active, .ul5PpKahXiZeyE1oz8hj.KoJUvuQ1CagYe76zvfm2{
    color:#f8c233;
  }
.mMtc6MzoEIoG4l9glioa:hover, .mMtc6MzoEIoG4l9glioa:active, .mMtc6MzoEIoG4l9glioa.KoJUvuQ1CagYe76zvfm2{
    color:#e94149;
  }
.UJzLhTMVHkRDChVvsGQz:hover, .UJzLhTMVHkRDChVvsGQz:active, .UJzLhTMVHkRDChVvsGQz.KoJUvuQ1CagYe76zvfm2{
    color:#f79dd0;
  }
.ukGZTBLzT5LYKEWnRcnL:hover, .ukGZTBLzT5LYKEWnRcnL:active, .ukGZTBLzT5LYKEWnRcnL.KoJUvuQ1CagYe76zvfm2{
    color:#67b868;
  }
.YHc_YedS6iBp8A59Obko:hover, .YHc_YedS6iBp8A59Obko:active, .YHc_YedS6iBp8A59Obko.KoJUvuQ1CagYe76zvfm2{
    color:#5ccff5;
  }
.kSDSn48Sfg5cPsK2W63v:hover, .kSDSn48Sfg5cPsK2W63v:active, .kSDSn48Sfg5cPsK2W63v.KoJUvuQ1CagYe76zvfm2{
    color:#7850fc;
  }
.S_uuGOKd74duf1wfNEaU:hover, .S_uuGOKd74duf1wfNEaU:active, .S_uuGOKd74duf1wfNEaU.KoJUvuQ1CagYe76zvfm2{
    color:#e9579e;
  }
.lq8xtP09yACP3NzvJlJM:hover, .lq8xtP09yACP3NzvJlJM:active, .lq8xtP09yACP3NzvJlJM.KoJUvuQ1CagYe76zvfm2{
    color:#b9c3ce;
  }
.TT53ZaQqC4om7kOwtCdl:hover, .TT53ZaQqC4om7kOwtCdl:active, .TT53ZaQqC4om7kOwtCdl.KoJUvuQ1CagYe76zvfm2{
    color:#2d80eb;
  }
.XZgAD8qTjPUyJ5_gytQa:hover, .XZgAD8qTjPUyJ5_gytQa:active, .XZgAD8qTjPUyJ5_gytQa.KoJUvuQ1CagYe76zvfm2{
    color:#ff5c73;
  }
.OPfokfR9dY2E_lwEXIYv:hover, .OPfokfR9dY2E_lwEXIYv:active, .OPfokfR9dY2E_lwEXIYv.KoJUvuQ1CagYe76zvfm2{
    color:#f9f9f9;
  }
.g0DWdDpVerqlgB9_RvFh{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.g0DWdDpVerqlgB9_RvFh:hover{
    box-shadow:1px 1px 3px 1px #1584fc;
  }
.Gl7jmvsFe7ijM1aPbsD3{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.Gl7jmvsFe7ijM1aPbsD3:hover{
    box-shadow:1px 1px 3px 1px #57bec7;
  }
.wS3TbK5HKk3axxC34cDg{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.wS3TbK5HKk3axxC34cDg:hover{
    box-shadow:1px 1px 3px 1px #f8c233;
  }
.vjniRWDgqDi4Hfeyd_tJ{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.vjniRWDgqDi4Hfeyd_tJ:hover{
    box-shadow:1px 1px 3px 1px #e94149;
  }
.Q2c3gpSRtWtWYy50Gvyu{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.Q2c3gpSRtWtWYy50Gvyu:hover{
    box-shadow:1px 1px 3px 1px #f79dd0;
  }
.jrhFuodzt9xveIknVamL{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.jrhFuodzt9xveIknVamL:hover{
    box-shadow:1px 1px 3px 1px #67b868;
  }
.sYDQilrC6I9ZiE0Uastw{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.sYDQilrC6I9ZiE0Uastw:hover{
    box-shadow:1px 1px 3px 1px #5ccff5;
  }
.aVNUAUp0CYj50_KONf_b{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.aVNUAUp0CYj50_KONf_b:hover{
    box-shadow:1px 1px 3px 1px #7850fc;
  }
.KMQDWflv6FtbwJB_1tqE{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.KMQDWflv6FtbwJB_1tqE:hover{
    box-shadow:1px 1px 3px 1px #e9579e;
  }
.BZwBoI2JRSMZmb2RmXOK{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.BZwBoI2JRSMZmb2RmXOK:hover{
    box-shadow:1px 1px 3px 1px #b9c3ce;
  }
.mD2VxlYFCMG3bdLM4fhv{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.mD2VxlYFCMG3bdLM4fhv:hover{
    box-shadow:1px 1px 3px 1px #2d80eb;
  }
.KqsYeXcDkQHcqZ68s9lO{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  }
.KqsYeXcDkQHcqZ68s9lO:hover{
    box-shadow:1px 1px 3px 1px #ff5c73;
  }
.e9VdQpiq9grnY8TCEmlw{
  box-shadow:1px 1px 4px 0 #b9c3ce;
  transition:box-shadow 0.5s cubic-bezier(0.2,1,0.1,1), opacity 150ms;
  
  }
.e9VdQpiq9grnY8TCEmlw:hover{
    box-shadow:1px 1px 3px 1px #f9f9f9;
  }
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteTwoColor": `#ffffff`,
	"whiteSevenColor": `#f8f8f8`,
	"whiteFiveColor": `#eaeaea`,
	"cloudyBlueColor": `#b9c3ce`,
	"warmGreyThreeColor": `#777777`,
	"warmGreyFourColor": `#8e8e8e`,
	"warmGreyFiveColor": `#878787`,
	"pinkishGreyThree": `#c9c9c9`,
	"blackColor": `#000000`,
	"mediumPinkTwo": `#ea5074`,
	"darkPink": `#cf3256`,
	"darkSixColor": `#0c1322`,
	"darkNineColor": `#162432`,
	"pigPink": `#ec8da3`,
	"veryLightPink": `#fffefe`,
	"maudlinOysterColor": `#c0c0c0`,
	"haplessSnowColor": `#e2e2e2`,
	"mercuryColor": `#e1e1e1`,
	"pinkishGreyTwoColor": `#bcbcbc`,
	"midnightColor": `#030a1b`,
	"paleGreyThreeColor": `#f3f7fb`,
	"paleGreyFourColor": `#ebf0f4`,
	"porcelainColor": `#E9EAEB`,
	"blackSqueezeColor": `#F2F7FB`,
	"catskillWhite": `#DFE9F0`,
	"redRibbonColor": `#E90242`,
	"trueGreenColor": `#199800`,
	"greyishColor": `#a3a3a3`,
	"blueGreyThreeColor": `#6c839c`,
	"slateThreeColor": `#425568`,
	"blueGreyFourColor": `#687e97`,
	"fiordColor": `#394D60`,
	"darkSevenColor": `#101b25`,
	"veryLightPinkColor": `#fffefe`,
	"tomatoColor": `#eb2d2d`,
	"bermudaGreyColor": `#6D839D`,
	"paleCornflowerBlueColor": `#C8E3F9`,
	"tangaroaColor": `#0F1C26`,
	"hokiColor": `#68859E`,
	"slateFourColor": `#4d6074`,
	"greyBlueTwoColor": `#6a8199`,
	"darkEightColor": `#13202c`,
	"whiteColor": `#f1f1f1`,
	"black05Color": `rgba(0, 0, 0, 0.05)`,
	"black10Color": `rgba(0, 0, 0, 0.1)`,
	"black15Color": `rgba(0, 0, 0, 0.15)`,
	"black25Color": `rgba(0, 0, 0, 0.25)`,
	"black20Color": `rgba(0, 0, 0, 0.2)`,
	"black40Color": `rgba(0, 0, 0, 0.4)`,
	"black55Color": `rgba(0, 0, 0, 0.55)`,
	"black50Color": `rgba(0, 0, 0, 0.5)`,
	"black60Color": `rgba(0, 0, 0, 0.6)`,
	"whiteThreeColor": `#d8d8d8`,
	"whiteFourColor": `#f9f9f9`,
	"whiteSixColor": `#fcfcfc`,
	"whiteTwo8Color": `rgba(255, 255, 255, 0.08)`,
	"dodgerBlueColor": `#2f8fff`,
	"blueGreyColor": `#7088a1`,
	"blueGrey50Color": `rgba(112, 136, 161, 0.5)`,
	"blueGreyTwoColor": `#788fa6`,
	"bermudaGrayColor": `#7088A1`,
	"blueyGreyColor": `#a3afbc`,
	"blueyGrey60Color": `rgba(112, 136, 161, 0.6)`,
	"clearBlueColor": `#1584fc`,
	"darkGreyBlueColor": `#283949`,
	"seafoamBlueColor": `#57bec7`,
	"lightBlueColor": `#5ccff5`,
	"babyBlueColor": `#b7d8ff`,
	"cloudyBlue20Color": `rgba(185, 195, 206, 0.2)`,
	"cloudyBlue30Color": `rgba(185, 195, 206, 0.3)`,
	"cloudyBlue50Color": `rgba(185, 195, 206, 0.5)`,
	"slate60Color": `rgba(86, 104, 122, 0.6)`,
	"lightGreyColor": `#f8f9f9`,
	"warmGreyColor": `#979797`,
	"warmGrey5Color": `rgba(126, 126, 126, 0.05)`,
	"warmGrey10Color": `rgba(126, 126, 126, 0.10)`,
	"warmGrey15Color": `rgba(126, 126, 126, 0.15)`,
	"lightGreenColor": `#42ffc0`,
	"pinkishGreyColor": `#c8c8c8`,
	"coralPinkColor": `#ff5c73`,
	"macaroniAndCheeseColor": `#f8c233`,
	"lipstickColor": `#e94149`,
	"softPinkColor": `#f79dd0`,
	"darkPeachColor": `#67b868`,
	"cornFlowerColor": `#7850fc`,
	"mediumPinkColor": `#e9579e`,
	"paleRedColor": `#e54b4b`,
	"darkTwoColor": `#1f2e3d`,
	"silverColor": `#e4e5e5`,
	"slateColor": `#56687a`,
	"mortarColor": `rgba(91, 91, 91, 0.07)`,
	"wisteriaColor": `#2d80eb`,
	"wisteria15Color": `#2466bc`,
	"wisteria15FlatColor": `#6ca6f1`,
	"wisteria50Color": `#1b4d8d`,
	"silverTwoColor": `#dedfdf`,
	"darkGreyBlueTwoColor": `#293c4f`,
	"bismarkColor": `#466784`,
	"paleGreyColor": `#f3f3f4`,
	"darkBlueGreyColor": `#111f2d`,
	"darkThreeColor": `#1a2939`,
	"darkFourColor": `#212035`,
	"brownishGreyColor": `#636363`,
	"greyish50Color": `rgba(177, 177, 177, 0.5)`,
	"slateTwoColor": `#435567`,
	"mangoColor": `#ffb02b`,
	"darkMintColor": `#5cc984`,
	"dodgerBlueTwoColor": `#56a0f6`,
	"coralPinkTwoColor": `#ff575f`,
	"duskColor": `#49627b`,
	"gossamerColor": `#0ba765`,
	"jungleGreenColor": `#25bc7c`,
	"jetStreamColor": `#b2d5c9`,
	"ghostColor": `#c6cbd1`,
	"ironColor": `#dfe2e5`,
	"aquaHazeColor": `#f6f8fa`,
	"lochmaraColor": `#0576B9`,
	"bahamaBlueColor": `#005E99`,
	"tealishColor": `#37cfad`,
	"darkSkyBlueColor": `#4A90E2`,
	"outerSpaceColor90": `rgba(50, 60, 63, 0.9)`,
	"brightBlueColor": `#007aff`,
	"mediumCarmineColor": `#b63a3a`,
	"orangePeelColor": `#f79a00`,
	"greenHazeColor": `#009a59`,
	"galleryColor": `#eaeaea`,
	"elephantColor": `#10263c`,
	"greyishBrownColor": `#4a4a4a`,
	"blueyGreyTwo": `#a3b0be`,
	"paleGreyTwoColor": `#dbe3ec`,
	"metallicBlueColor": `#4c6580`,
	"steelTwoColor": `#7c8895`,
	"gridCellSize": `5px`,
	"headerFontSize": `18px`,
	"fontFamilyThree": `"Poppins", sans-serif`,
	"topMenuHeight": `50px`,
	"microFontSize": `9px`,
	"smallerFontSize": `10px`,
	"mediumSmallFontSize": `11px`,
	"smallFontSize": `12px`,
	"normalFontSize": `14px`,
	"mediumFontSize": `16px`,
	"headingFontSize": `19px`,
	"largeFontSize": `20px`,
	"largerFontSize": `24px`,
	"thinFontWeight": `100`,
	"lightFontWeight": `300`,
	"regularFontWeight": `400`,
	"mediumFontWeight": `500`,
	"semiboldFontWeight": `600`,
	"boldFontWeight": `700`,
	"blackFontWeight": `900`,
	"modalTransitionDuration": `90ms`,
	"backdropEnteredOpacity": `0.33`,
	"backdropOpacity": `0.9`,
	"deactivatedOpacity": `0.85`,
	"defaultFontFamily": `"Poppins", sans-serif`,
	"fontFamilyTwo": `"Poppins", sans-serif`,
	"defaultBorderWidth": `2px`,
	"defaultOutlineWidth": `2px`,
	"defaultBorderRadius": `4px`,
	"largeBorderRadius": `8px`,
	"largerBorderRadius": `18px`,
	"defaultOutline": `0 0 0 2px rgba(126, 126, 126, 0.15)`,
	"defaultPopoverShadow": `0 5px 15px 0 rgba(0, 0, 0, 0.25)`,
	"headerHeight": `55px`,
	"rightMenuWidth": `310px`,
	"messageTextLineHeight": `20px`,
	"leftMenuWidth": `300px`,
	"cardDetailsHeight": `28px`,
	"defaultTransition": `0.2s`,
	"defaultTransitionAnimation": `0.5s cubic-bezier(0.2,1,0.1,1)`,
	"defaultTransitionEasing": `cubic-bezier(0.2,1,0.1,1)`,
	"mainMenuWidth": `300px`,
	"defaultIconWidth": `12`,
	"phoneBreakpointWidth": `425px`,
	"tabletBreakpointWidth": `915px`,
	"largeDesktop": `1600px`,
	"mobileBreakpoint": `1050px`,
	"object-color-text-clearBlueColor": `MRJ2Fe3VdRIfPeZwG88l`,
	"object-color-text-seafoamBlueColor": `sQxlIjErf9vpi1N2EVi5`,
	"object-color-text-macaroniAndCheeseColor": `fJUxp8xrNLTVJ0tOA4q6`,
	"object-color-text-lipstickColor": `wewg4NfmPzaNCZopGnQQ`,
	"object-color-text-softPinkColor": `izh_JLEYEnAwwtoiLngW`,
	"object-color-text-darkPeachColor": `v1dRE88RSKk3KiDNXpMm`,
	"object-color-text-lightBlueColor": `Care7Yt2D1h2CZjZktyl`,
	"object-color-text-cornFlowerColor": `ghIiJRNGgDr4jaFrmNog`,
	"object-color-text-mediumPinkColor": `nAsHCm29tbzGLyyVrg9B`,
	"object-color-text-cloudyBlueColor": `UshDh54a4bqJ_5FPZsOw`,
	"object-color-text-wisteriaColor": `_BIaUtisIineZzpxAXL0`,
	"object-color-text-coralPinkColor": `YIbLnnZfanMPK8XFjDR9`,
	"object-color-text-whiteFourColor": `fgbB6AdkHmoZz10d5wNj`,
	"object-color-secondary-button-clearBlueColor": `tzACBanFLFxOYM4_NJzL`,
	"disabled": `TXtD7pfqzBZsanVNeEp7`,
	"active": `KoJUvuQ1CagYe76zvfm2`,
	"fillWithColor": `Fxp1o7CbM_kSaxjBogmy`,
	"object-color-secondary-button-seafoamBlueColor": `oWxDDAKxyO9GKsGRLbT3`,
	"object-color-secondary-button-macaroniAndCheeseColor": `gAAQA1ht4u4M9vsJEMyI`,
	"object-color-secondary-button-lipstickColor": `ysGMUHgSH4kSXGYkhP79`,
	"object-color-secondary-button-softPinkColor": `EgsemUYJs8CYJ_v_PEHO`,
	"object-color-secondary-button-darkPeachColor": `PfvgsRGCVmCYMgzb3kOV`,
	"object-color-secondary-button-lightBlueColor": `wqBHW0juySCCbnWaEnfU`,
	"object-color-secondary-button-cornFlowerColor": `v1dSwY445iBmfcVT6CIM`,
	"object-color-secondary-button-mediumPinkColor": `W39QHkPLcNkKME31SlEN`,
	"object-color-secondary-button-cloudyBlueColor": `iuHFLCNLqyx6IL9NryLh`,
	"object-color-secondary-button-wisteriaColor": `fUx9vXkkpvv8m2y57VOT`,
	"object-color-secondary-button-coralPinkColor": `f_M1iCAcCDbMBBippsDg`,
	"object-color-secondary-button-whiteFourColor": `ava2G_YFsiR5tcjmwnRT`,
	"object-color-secondary-button-whiteTwoColor": `bVZ3mursm0TNqum4c_lV`,
	"object-color-secondary-button-lightGreyColor": `uFwutwoejoJ2po6v28dA`,
	"object-color-primary-button-clearBlueColor": `APSmALQ2XmNsxllcMqHC`,
	"object-color-primary-button-seafoamBlueColor": `BfVZ7Hl8fk6BNXepjIg_`,
	"object-color-primary-button-macaroniAndCheeseColor": `ucFoYIwKURB3gE6NUemU`,
	"object-color-primary-button-lipstickColor": `QxpUPv5J9wuWoKLUH9h_`,
	"object-color-primary-button-softPinkColor": `ekG0acjZG0d1IMaPNtWg`,
	"object-color-primary-button-darkPeachColor": `PVP9QOPXxB8KjPP7WlHd`,
	"object-color-primary-button-lightBlueColor": `bGskY1p8Zrr59saFBiYM`,
	"object-color-primary-button-cornFlowerColor": `ao48kJvcoOCdjXyw_1x7`,
	"object-color-primary-button-mediumPinkColor": `T8Lvej91DHjZJwHRpR_u`,
	"object-color-primary-button-cloudyBlueColor": `pArzHfL3eN7bbQf2AQLI`,
	"object-color-primary-button-wisteriaColor": `zMGbUWp7MlJkSmeoqoXg`,
	"object-color-primary-button-coralPinkColor": `zlZNcaXSz8WlOlSPg6FR`,
	"object-color-primary-button-whiteFourColor": `OXp1sYNrggU8mXoPYlIw`,
	"object-color-menu-button-clearBlueColor": `BOH33dvnz0xVI4KWjXgR`,
	"object-color-menu-button-seafoamBlueColor": `AXspO9CWOm0NzN59GUbg`,
	"object-color-menu-button-macaroniAndCheeseColor": `ul5PpKahXiZeyE1oz8hj`,
	"object-color-menu-button-lipstickColor": `mMtc6MzoEIoG4l9glioa`,
	"object-color-menu-button-softPinkColor": `UJzLhTMVHkRDChVvsGQz`,
	"object-color-menu-button-darkPeachColor": `ukGZTBLzT5LYKEWnRcnL`,
	"object-color-menu-button-lightBlueColor": `YHc_YedS6iBp8A59Obko`,
	"object-color-menu-button-cornFlowerColor": `kSDSn48Sfg5cPsK2W63v`,
	"object-color-menu-button-mediumPinkColor": `S_uuGOKd74duf1wfNEaU`,
	"object-color-menu-button-cloudyBlueColor": `lq8xtP09yACP3NzvJlJM`,
	"object-color-menu-button-wisteriaColor": `TT53ZaQqC4om7kOwtCdl`,
	"object-color-menu-button-coralPinkColor": `XZgAD8qTjPUyJ5_gytQa`,
	"object-color-menu-button-whiteFourColor": `OPfokfR9dY2E_lwEXIYv`,
	"object-color-box-shadow-clearBlueColor": `g0DWdDpVerqlgB9_RvFh`,
	"object-color-box-shadow-seafoamBlueColor": `Gl7jmvsFe7ijM1aPbsD3`,
	"object-color-box-shadow-macaroniAndCheeseColor": `wS3TbK5HKk3axxC34cDg`,
	"object-color-box-shadow-lipstickColor": `vjniRWDgqDi4Hfeyd_tJ`,
	"object-color-box-shadow-softPinkColor": `Q2c3gpSRtWtWYy50Gvyu`,
	"object-color-box-shadow-darkPeachColor": `jrhFuodzt9xveIknVamL`,
	"object-color-box-shadow-lightBlueColor": `sYDQilrC6I9ZiE0Uastw`,
	"object-color-box-shadow-cornFlowerColor": `aVNUAUp0CYj50_KONf_b`,
	"object-color-box-shadow-mediumPinkColor": `KMQDWflv6FtbwJB_1tqE`,
	"object-color-box-shadow-cloudyBlueColor": `BZwBoI2JRSMZmb2RmXOK`,
	"object-color-box-shadow-wisteriaColor": `mD2VxlYFCMG3bdLM4fhv`,
	"object-color-box-shadow-coralPinkColor": `KqsYeXcDkQHcqZ68s9lO`,
	"object-color-box-shadow-whiteFourColor": `e9VdQpiq9grnY8TCEmlw`
};
export default ___CSS_LOADER_EXPORT___;
