export const domain = 'ExtensionsModel'

export const onInit = `${domain}/onInit`

export const onSetIsExtensionEnabledForOrganization = `${domain}/onSetIsExtensionEnabledForOrganization`
export const onSetIsExtensionEnabledForOrganizationSuccess = `${domain}/onSetIsExtensionEnabledForOrganizationSuccess`

export const onSetIsExtensionEnabledForSpace = `${domain}/onSetIsExtensionEnabledForSpace`
export const onSetExtensionEnabledStatus = `${domain}/onSetExtensionEnabledStatus`

export const onFetchOrganizationExtensions = `${domain}/onFetchOrganizationExtensions`
export const onFetchOrganizationExtensionsSuccess = `${domain}/onFetchOrganizationExtensionsSuccess`

export const onUpdateExtensionInOrganizationData = `${domain}/onUpdateExtensionInOrganizationData`

export const onSetExtensionCustomFieldValue = `${domain}/onSetExtensionCustomFieldValue`

export const onUpdateExtensionCustomFieldValue = `${domain}/onUpdateExtensionCustomFieldValue`
export const onBatchCustomFieldValues = `${domain}/onBatchCustomFieldValues`

export const onFetchCustomFields = `${domain}/onFetchCustomFields`

export const onFetchCustomRelation = `${domain}/onFetchCustomRelation`