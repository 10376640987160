export const domain = 'ChatWithMeModel'

export const onJoinChat = `${domain}/onJoinChat`

export const onGenerateInvitationToken = `${domain}/onGenerateInvitationToken`

export const onSetInvitationToken = `${domain}/onSetInvitationToken`
export const onFetchInvitationToken = `${domain}/onFetchInvitationToken`
export const onFetchInvitationTokenSuccess = `${domain}/onFetchInvitationTokenSuccess`

export const onFetchInvitationData = `${domain}/onFetchInvitationData`
export const onFetchInvitationDataSuccess = `${domain}/onFetchInvitationDataSuccess`
