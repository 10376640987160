import React from 'react';

import { ConnectedRouter } from 'connected-react-router/immutable';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import { history, RootStore } from 'common/rootStore';

if (process.env.NODE_ENV !== 'production' && process.env.DEBUG_PERF === 'true') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    exclude: [
      // React Router
      /^Route/,
    ],
    trackAllPureComponents: true,
    include: [/(.*?)/],
    groupByComponent: true,
    collapseGroups: true,
    titleColor: 'yellow',
    diffNameColor: 'yellow',
    diffPathColor: 'organge',
  });
}

const domRoot = document.getElementById('app');

const RenderApp = () => {
  const App = require('./components/App').default;
  ReactDOM.render(
    <StoreProvider store={RootStore}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </StoreProvider>,
    domRoot
  );
};

function Component() {
  RenderApp();
}

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./components/App', () => {
    setTimeout(() => {
      RenderApp();
    });
  });
}

export default Component;
