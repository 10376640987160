export const domain = 'FiltersModel'

export const onInit = `${domain}/onInit`

export const onSetFilterBy = `${domain}/onSetFilterBy`
export const onSetFilterBySuccess = `${domain}/onSetFilterBySuccess`

export const onSetGroupBy = `${domain}/onSetGroupBy`
export const onSetGroupBySuccess = `${domain}/onSetGroupBySuccess`

export const onSetSortBy = `${domain}/onSetSortBy`
export const onSetSortBySuccess = `${domain}/onSetSortBySuccess`

export const onClearFilters = `${domain}/onClearFilters`
export const onClearFiltersSuccess = `${domain}/onClearFiltersSuccess`

export const onBatchFilters = `${domain}/onBatchFilters`

