export const domain = 'GuestsModel'

export const onInit = `${domain}/onInit`
export const onAddGuestInProject = `${domain}/onAddGuestInProject`
export const onBatchGuestInProject = `${domain}/onBatchGuestInProject`

export const onCreateGuestInProject = `${domain}/onCreateGuestInProject`
export const onUpdateGuestInProject = `${domain}/onUpdateGuestInProject`
export const onDeleteGuestInProject = `${domain}/onDeleteGuestInProject`

export const onRemoveGuestInProject = `${domain}/onRemoveGuestInProject`
