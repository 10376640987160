// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../styles/fonts/Poppins-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../styles/fonts/Poppins-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../styles/fonts/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../styles/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../styles/fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../styles/fonts/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../../styles/fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../../styles/fonts/Poppins-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../../styles/fonts/Poppins-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  height:100%;
}
body{
  width:100%;
  height:100%;
  margin:0px;
  padding:0px;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight:100;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight:200;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight:300;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  font-weight:400;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
  font-weight:500;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
  font-weight:600;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
  font-weight:700;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype');
  font-weight:800;
}
@font-face{
  font-family:'Poppins';
  src:url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('truetype');
  font-weight:900;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (-webkit-min-device-pixel-ratio: 2.0833333333333335), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx){
  html{
    -webkit-font-smoothing:subpixel-antialiased;
  }
}
html{
  height:100%;
  width:100%;
  overflow:hidden;
}
body{
  height:100%;
  width:100%;
}
body{
  color:var(--color-gray-900);
  font-family:var(--fontFamily);
  font-weight:var(--fontWeight-regular);
  font-size:var(--fontSize-sm);
  text-decoration:none;
}
svg{
  fill:none;
}
p{
  margin:0;
  padding:0;
}
table{
  border-collapse:collapse;
  border-spacing:0;
}
tr{
  border-top:1px solid #c6cbd1;
  background:#ffffff;
}
tr:nth-child(2n){
  background:#f6f8fa;
}
th{
  padding:6px 13px;
  border:1px solid #dfe2e5;
}
td{
  padding:6px 13px;
  border:1px solid #dfe2e5;
}
ol{
  padding:0;
  margin:0;
  list-style-type:decimal;
  list-style-position:inside;
}
ul{
  padding:0;
  margin:0;
  list-style-type:disc;
  list-style-position:inside;
}
#description-editor .ql-container{
    height:auto;
    border:1px solid transparent;
    border-radius:8px;
  }
#description-editor:hover .ql-container, #description-editor.active .ql-container{
    border-color:#f1f1f1;
  }
#description-editor .ql-editor{
    height:auto;
    min-height:50px;
  }
#description-editor .ql-toolbar{
    padding-bottom:3px;
    display:none;
    border:1px solid #f1f1f1;
    border-radius:8px;
  }
#description-editor.active .ql-toolbar{
    display:block;
    margin-top:10px;
    margin-bottom:10px;
  }
#description-editor .ql-formats{
    margin:0 5px 5px 0;
    border-radius:3px;
    border:1px solid #e4e4e4;
  }
#newMessageEditor .ql-editor.ql-blank::before{
    font-style:normal;
    color:rgba(86, 104, 122, 0.6);
    width:calc(100% - 150px);
    height:20px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    padding-left:25px
  }
@media only screen and (max-width: 1050px){

  #newMessageEditor .ql-editor.ql-blank::before{
      padding-left:0
  }
    }
#messageBeingEditedEditor .ql-editor{
    padding-right:30px;
  }
#newMessageEditor .ql-editor{
    padding:15px 130px 15px 42px
  }
@media only screen and (max-width: 1050px){

  #newMessageEditor .ql-editor{
      padding-left:1.5rem
  }
    }
#newMessageEditor .ql-editor, #newMessageEditor .ql-container{
    height:auto;
    max-height:300px;
    font-size:14px;
  }
.timeline-day{
    border-left:none !important;
  }
.timeline-month{
    border-left:none !important;
  }
.rct-scroll{
    overflow-x:hidden !important;
  }
.rct-calendar-header{
    border:none !important;
  }
.rct-header-root{
    border:none !important;
    background:transparent !important;
  }
body #ue_widget{
    z-index:500 !important;
  }
input,
textarea,
select,
button,
p,
label,
a{
  font-family:inherit;
  font-weight:inherit;
  color:inherit;
  text-decoration:none;
}
abbr[title]{
  text-decoration:none;
}
textarea:focus,
input:focus,
*:focus{
  outline:none;
}
::-webkit-scrollbar{
  width:8px;
  height:6px;
}
::-webkit-scrollbar-track{
  background:transparent;
}
::-webkit-scrollbar-thumb{
  background:rgba(0, 0, 0, 0.2);
  border-radius:3px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteTwoColor": `#ffffff`,
	"whiteSevenColor": `#f8f8f8`,
	"whiteFiveColor": `#eaeaea`,
	"cloudyBlueColor": `#b9c3ce`,
	"warmGreyThreeColor": `#777777`,
	"warmGreyFourColor": `#8e8e8e`,
	"warmGreyFiveColor": `#878787`,
	"pinkishGreyThree": `#c9c9c9`,
	"blackColor": `#000000`,
	"mediumPinkTwo": `#ea5074`,
	"darkPink": `#cf3256`,
	"darkSixColor": `#0c1322`,
	"darkNineColor": `#162432`,
	"pigPink": `#ec8da3`,
	"veryLightPink": `#fffefe`,
	"maudlinOysterColor": `#c0c0c0`,
	"haplessSnowColor": `#e2e2e2`,
	"mercuryColor": `#e1e1e1`,
	"pinkishGreyTwoColor": `#bcbcbc`,
	"midnightColor": `#030a1b`,
	"paleGreyThreeColor": `#f3f7fb`,
	"paleGreyFourColor": `#ebf0f4`,
	"porcelainColor": `#E9EAEB`,
	"blackSqueezeColor": `#F2F7FB`,
	"catskillWhite": `#DFE9F0`,
	"redRibbonColor": `#E90242`,
	"trueGreenColor": `#199800`,
	"greyishColor": `#a3a3a3`,
	"blueGreyThreeColor": `#6c839c`,
	"slateThreeColor": `#425568`,
	"blueGreyFourColor": `#687e97`,
	"fiordColor": `#394D60`,
	"darkSevenColor": `#101b25`,
	"veryLightPinkColor": `#fffefe`,
	"tomatoColor": `#eb2d2d`,
	"bermudaGreyColor": `#6D839D`,
	"paleCornflowerBlueColor": `#C8E3F9`,
	"tangaroaColor": `#0F1C26`,
	"hokiColor": `#68859E`,
	"slateFourColor": `#4d6074`,
	"greyBlueTwoColor": `#6a8199`,
	"darkEightColor": `#13202c`,
	"whiteColor": `#f1f1f1`,
	"black05Color": `rgba(0, 0, 0, 0.05)`,
	"black10Color": `rgba(0, 0, 0, 0.1)`,
	"black15Color": `rgba(0, 0, 0, 0.15)`,
	"black25Color": `rgba(0, 0, 0, 0.25)`,
	"black20Color": `rgba(0, 0, 0, 0.2)`,
	"black40Color": `rgba(0, 0, 0, 0.4)`,
	"black55Color": `rgba(0, 0, 0, 0.55)`,
	"black50Color": `rgba(0, 0, 0, 0.5)`,
	"black60Color": `rgba(0, 0, 0, 0.6)`,
	"whiteThreeColor": `#d8d8d8`,
	"whiteFourColor": `#f9f9f9`,
	"whiteSixColor": `#fcfcfc`,
	"whiteTwo8Color": `rgba(255, 255, 255, 0.08)`,
	"dodgerBlueColor": `#2f8fff`,
	"blueGreyColor": `#7088a1`,
	"blueGrey50Color": `rgba(112, 136, 161, 0.5)`,
	"blueGreyTwoColor": `#788fa6`,
	"bermudaGrayColor": `#7088A1`,
	"blueyGreyColor": `#a3afbc`,
	"blueyGrey60Color": `rgba(112, 136, 161, 0.6)`,
	"clearBlueColor": `#1584fc`,
	"darkGreyBlueColor": `#283949`,
	"seafoamBlueColor": `#57bec7`,
	"lightBlueColor": `#5ccff5`,
	"babyBlueColor": `#b7d8ff`,
	"cloudyBlue20Color": `rgba(185, 195, 206, 0.2)`,
	"cloudyBlue30Color": `rgba(185, 195, 206, 0.3)`,
	"cloudyBlue50Color": `rgba(185, 195, 206, 0.5)`,
	"slate60Color": `rgba(86, 104, 122, 0.6)`,
	"lightGreyColor": `#f8f9f9`,
	"warmGreyColor": `#979797`,
	"warmGrey5Color": `rgba(126, 126, 126, 0.05)`,
	"warmGrey10Color": `rgba(126, 126, 126, 0.10)`,
	"warmGrey15Color": `rgba(126, 126, 126, 0.15)`,
	"lightGreenColor": `#42ffc0`,
	"pinkishGreyColor": `#c8c8c8`,
	"coralPinkColor": `#ff5c73`,
	"macaroniAndCheeseColor": `#f8c233`,
	"lipstickColor": `#e94149`,
	"softPinkColor": `#f79dd0`,
	"darkPeachColor": `#67b868`,
	"cornFlowerColor": `#7850fc`,
	"mediumPinkColor": `#e9579e`,
	"paleRedColor": `#e54b4b`,
	"darkTwoColor": `#1f2e3d`,
	"silverColor": `#e4e5e5`,
	"slateColor": `#56687a`,
	"mortarColor": `rgba(91, 91, 91, 0.07)`,
	"wisteriaColor": `#2d80eb`,
	"wisteria15Color": `#2466bc`,
	"wisteria15FlatColor": `#6ca6f1`,
	"wisteria50Color": `#1b4d8d`,
	"silverTwoColor": `#dedfdf`,
	"darkGreyBlueTwoColor": `#293c4f`,
	"bismarkColor": `#466784`,
	"paleGreyColor": `#f3f3f4`,
	"darkBlueGreyColor": `#111f2d`,
	"darkThreeColor": `#1a2939`,
	"darkFourColor": `#212035`,
	"brownishGreyColor": `#636363`,
	"greyish50Color": `rgba(177, 177, 177, 0.5)`,
	"slateTwoColor": `#435567`,
	"mangoColor": `#ffb02b`,
	"darkMintColor": `#5cc984`,
	"dodgerBlueTwoColor": `#56a0f6`,
	"coralPinkTwoColor": `#ff575f`,
	"duskColor": `#49627b`,
	"gossamerColor": `#0ba765`,
	"jungleGreenColor": `#25bc7c`,
	"jetStreamColor": `#b2d5c9`,
	"ghostColor": `#c6cbd1`,
	"ironColor": `#dfe2e5`,
	"aquaHazeColor": `#f6f8fa`,
	"lochmaraColor": `#0576B9`,
	"bahamaBlueColor": `#005E99`,
	"tealishColor": `#37cfad`,
	"darkSkyBlueColor": `#4A90E2`,
	"outerSpaceColor90": `rgba(50, 60, 63, 0.9)`,
	"brightBlueColor": `#007aff`,
	"mediumCarmineColor": `#b63a3a`,
	"orangePeelColor": `#f79a00`,
	"greenHazeColor": `#009a59`,
	"galleryColor": `#eaeaea`,
	"elephantColor": `#10263c`,
	"greyishBrownColor": `#4a4a4a`,
	"blueyGreyTwo": `#a3b0be`,
	"paleGreyTwoColor": `#dbe3ec`,
	"metallicBlueColor": `#4c6580`,
	"steelTwoColor": `#7c8895`,
	"gridCellSize": `5px`,
	"headerFontSize": `18px`,
	"fontFamilyThree": `"Poppins", sans-serif`,
	"topMenuHeight": `50px`,
	"microFontSize": `9px`,
	"smallerFontSize": `10px`,
	"mediumSmallFontSize": `11px`,
	"smallFontSize": `12px`,
	"normalFontSize": `14px`,
	"mediumFontSize": `16px`,
	"headingFontSize": `19px`,
	"largeFontSize": `20px`,
	"largerFontSize": `24px`,
	"thinFontWeight": `100`,
	"lightFontWeight": `300`,
	"regularFontWeight": `400`,
	"mediumFontWeight": `500`,
	"semiboldFontWeight": `600`,
	"boldFontWeight": `700`,
	"blackFontWeight": `900`,
	"modalTransitionDuration": `90ms`,
	"backdropEnteredOpacity": `0.33`,
	"backdropOpacity": `0.9`,
	"deactivatedOpacity": `0.85`,
	"defaultFontFamily": `"Poppins", sans-serif`,
	"fontFamilyTwo": `"Poppins", sans-serif`,
	"defaultBorderWidth": `2px`,
	"defaultOutlineWidth": `2px`,
	"defaultBorderRadius": `4px`,
	"largeBorderRadius": `8px`,
	"largerBorderRadius": `18px`,
	"defaultOutline": `0 0 0 2px rgba(126, 126, 126, 0.15)`,
	"defaultPopoverShadow": `0 5px 15px 0 rgba(0, 0, 0, 0.25)`,
	"headerHeight": `55px`,
	"rightMenuWidth": `310px`,
	"messageTextLineHeight": `20px`,
	"leftMenuWidth": `300px`,
	"cardDetailsHeight": `28px`,
	"defaultTransition": `0.2s`,
	"defaultTransitionAnimation": `0.5s cubic-bezier(0.2,1,0.1,1)`,
	"defaultTransitionEasing": `cubic-bezier(0.2,1,0.1,1)`,
	"mainMenuWidth": `300px`,
	"defaultIconWidth": `12`,
	"phoneBreakpointWidth": `425px`,
	"tabletBreakpointWidth": `915px`,
	"largeDesktop": `1600px`,
	"mobileBreakpoint": `1050px`
};
export default ___CSS_LOADER_EXPORT___;
