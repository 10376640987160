import { AbstractCustomerMessagingPlatform } from './AbstractCustomerMessagingPlatform';
import { DataProviderInterface, UserComDataInterface } from './types';

export class UserComClient extends AbstractCustomerMessagingPlatform<UserComDataInterface> {
  constructor(private customerMessagingPlatformApiKey: string, isTrackingEnabled: boolean) {
    super(isTrackingEnabled);
  }

  getPayload(data: DataProviderInterface): UserComDataInterface {
    return {
      apiKey: this.customerMessagingPlatformApiKey,
      user_id: data.currentUserId,
      email: data.email,
      name: `${data.firstName} ${data.lastName}`,
      current_organization_id: data.organizationId,
      current_organization_name: data.organizationName,
      phone_number: data.phoneNumber,
      'Plan name': data.planName,
      'Organization role': data.organizationRole,
      'Subscription status': data.subscriptionStatus,
      company: {
        company_id: data.organizationId,
        name: data.organizationName,
        plan: data.planName,
        monthly_spend: data.currentMonthBill,
        size: data.organizationUserCount,
      },
    };
  }

  hideChatWindow(): void {}
  showChatWindow(): void {}

  sendData(data: UserComDataInterface) {
    if (!this.customerMessagingPlatformApiKey) {
      throw Error('UserCom API key not provided');
    }

    if (!window.UE) {
      return;
    }
    window.UE.pageHit(data);
  }
}
