export const domain = 'OrganizationGroupsModel';

export const onInit = `${domain}/onInit`;

export const onSetGroupsData = `${domain}/onSetGroupsData`;
export const onSetGroupData = `${domain}/onSetGroupData`;
export const onSetGroupsPeople = `${domain}/onSetGroupsPeople`;

export const onCreateGroup = `${domain}/onCreateGroup`;
export const onCreateGroupSuccess = `${domain}/onCreateGroupSuccess`;

export const onUpdateGroup = `${domain}/onUpdateGroup`;
export const onUpdateGroupSuccess = `${domain}/onUpdateGroupSuccess`;

export const onDeleteGroup = `${domain}/onDeleteGroup`;
export const onDeleteGroupSuccess = `${domain}/onDeleteGroupSuccess`;

export const onAddUserToGroup = `${domain}/onAddUserToGroup`;
export const onAddUserToGroupSuccess = `${domain}/onAddUserToGroupSuccess`;

export const onRemoveUserFromGroup = `${domain}/onRemoveUserFromGroup`;
export const onRemoveUserFromGroupSuccess = `${domain}/onRemoveUserFromGroupSuccess`;
