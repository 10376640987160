export const domain = 'ProjectsModel';

export const FIRST_PROJECT_NAME = 'first-project';
export const FIRST_PROJECT_PLACEHOLDER_PATH = '/projects/first-project';

export const onFetchProject = `${domain}/onFetchProject`;
export const onFetchProjectSuccess = `${domain}/onFetchProjectSuccess`;

export const onProjectCreate = `${domain}/onProjectCreate`;
export const onProjectCreateSuccess = `${domain}/onProjectCreateSuccess`;

export const onProjectUpdate = `${domain}/onProjectUpdate`;
export const onProjectUpdateSuccess = `${domain}/onProjectUpdateSuccess`;

export const onNameUpdate = `${domain}/onNameUpdate`;
export const onStatusUpdate = `${domain}/onStatusUpdate`;
export const onCodeUpdate = `${domain}/onCodeUpdate`;
export const onDescriptionUpdate = `${domain}/onDescriptionUpdate`;
export const onColorUpdate = `${domain}/onColorUpdate`;
export const onBackgroundUpdate = `${domain}/onBackgroundUpdate`;
export const onIconTypeUpdate = `${domain}/onIconTypeUpdate`;

export const onMemberUpdate = `${domain}/onMemberUpdate`;
export const onMemberRemove = `${domain}/onMemberRemove`;
export const onMemberAdd = `${domain}/onMemberAdd`;

export const onRemoveGuestFromAllSpaces = `${domain}/onRemoveGuestFromAllSpaces`;

export const onBatchProjects = `${domain}/onBatchProjects`;
export const onCreateProjectData = `${domain}/onCreateProjectData`;
export const onUpdateProjectData = `${domain}/onUpdateProjectData`;

export const onCreateProjectPeopleIds = `${domain}/onCreateProjectPeopleIds`;
export const onUpdateProjectPeopleIds = `${domain}/onUpdateProjectPeopleIds`;
export const onBatchProjectsPeopleIds = `${domain}/onBatchProjectsPeopleIds`;

export const onCreateProjectPeopleRole = `${domain}/onCreateProjectPeopleRole`;
export const onUpdateProjectPeopleRole = `${domain}/onUpdateProjectPeopleRole`;
export const onBatchProjectsPeopleRole = `${domain}/onBatchProjectsPeopleRole`;

export const onAddProjectFollower = `${domain}/onAddProjectFollower`;
export const onRemoveProjectFollower = `${domain}/onRemoveProjectFollower`;
export const onUpdateProjectFollowerIds = `${domain}/onUpdateProjectFollowerIds`;

export const onAddProjectPerson = `${domain}/onAddProjectPerson`;
export const onRemoveProjectPerson = `${domain}/onRemoveProjectPerson`;
export const onUpdateProjectPerson = `${domain}/onUpdateProjectPerson`;
export const onUpdateProjectPeopleSuccess = `${domain}/onUpdateProjectPeopleSuccess`;

export const onUpdateProjectPeople = `${domain}/onUpdateProjectPeople`;

export const onFollowerAdd = `${domain}/onFollowerAdd`;

export const onFollowerRemove = `${domain}/onFollowerRemove`;
export const onFollowerRemoveSuccess = `${domain}/onFollowerRemoveSuccess`;

export const onCreateUserAndAddToSubscribers = `${domain}/onCreateUserAndAddToSubscribers`;

export const onUpdateProjectPrivacy = `${domain}/onUpdateProjectPrivacy`;
export const onSetSectionScrollTop = `${domain}/onSetSectionScrollTop`;
export const onSetKanbanScrollLeft = `${domain}/onSetKanbanScrollLeft`;

export const onSetIsConversationVisible = `${domain}/onSetIsConversationVisible`;
export const onSetIsConversationVisibleSuccess = `${domain}/onSetIsConversationVisibleSuccess`;

export const onCreateConversationIfDoesNotExist = `${domain}/onCreateConversationIfDoesNotExist`;

export const onBatchProjectHasUnreadMessages = `${domain}/onBatchProjectHasUnreadMessages`;
export const onSetProjectHasUnreadMessages = `${domain}/onSetProjectHasUnreadMessages`;
export const onSetProjectsHaveUnreadMessages = `${domain}/onSetProjectsHaveUnreadMessages`;

export const onAddFileIdToProjectFileIds = `${domain}/onAddFileIdToProjectFileIds`;
export const onBatchProjectFileIds = `${domain}/onBatchProjectFileIds`;

export const onAddUsersToProject = `${domain}/onAddUsersToProject`;

export const onBatchConversationsVisibility = `${domain}/onBatchConversationsVisibility`;
export const onCreateConversationVisibilityData = `${domain}/onCreateConversationVisibilityData`;

export const onBatchOrganizationIdByProjectId = `${domain}/onBatchOrganizationIdByProjectId`;
export const onSetOrganizationIdByProjectId = `${domain}/onSetOrganizationIdByProjectId`;

export const onCopySpace = `${domain}/onCopySpace`;

export const onGetOrganizationProjects = `${domain}/onGetOrganizationProjects`;
export const onGetOrganizationProjectsSuccess = `${domain}/onGetOrganizationProjectsSuccess`;
export const onGetOrganizationProjectsPeople = `${domain}/onGetOrganizationProjectsPeople`;
export const onGetOrganizationProjectsHaveUnreadMessages = `${domain}/onGetOrganizationProjectsHaveUnreadMessages`;

export const onGetConversationSettings = `${domain}onGetConversationSettings`;

export const onGetFirstProjectSuccess = `${domain}/onGetFirstProjectSuccess`;
export const onSetProjectLatestVisit = `${domain}/onSetProjectLatestVisit`;

export const onCreateProjectFiles = `${domain}/onCreateProjectFiles`;

export const onUploadProjectFile = `${domain}/onUploadProjectFile`;
export const onDebounceRefetchProjectFiles = `${domain}/onDebounceRefetchProjectFiles`;
export const onUploadProjectBackground = `${domain}/onUploadProjectBackground`;
