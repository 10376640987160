export enum Type {
  INVALID_AUTH_TOKEN = 'INVALID_AUTH_TOKEN',
  INVALID_REQUEST_KEY = 'INVALID_REQUEST_KEY',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INTERNAL = 'INTERNAL',
  NOT_IMPLEMENTED_CONTAINER_TYPE = 'NOT_IMPLEMENTED_CONTAINER_TYPE',
  CONTAINER_NOT_FOUND = 'CONTAINER_NOT_FOUND',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  TASK_NOT_FOUND = 'TASK_NOT_FOUND',
  TASK_PEOPLE_NOT_FOUND = 'TASK_PEOPLE_NOT_FOUND',
  TASK_ALREADY_EXISTS = 'TASK_ALREADY_EXISTS',
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
  PROJECT_ALREADY_EXISTS = 'PROJECT_ALREADY_EXISTS',
  PROJECT_PEOPLE_NOT_FOUND = 'PROJECT_PEOPLE_NOT_FOUND',
  LIST_NOT_FOUND = 'LIST_NOT_FOUND',
  LIST_ALREADY_EXISTS = 'LIST_ALREADY_EXISTS',
  TAG_NOT_FOUND = 'TAG_NOT_FOUND',
  TAG_ALREADY_EXISTS = 'TAG_ALREADY_EXISTS',
  CHECKLIST_ITEM_ALREADY_EXISTS = 'CHECKLIST_ITEM_ALREADY_EXISTS',
  CHECKLIST_ITEM_NOT_FOUND = 'CHECKLIST_ITEM_NOT_FOUND',
  FILE_ALREADY_EXISTS = 'FILE_ALREADY_EXISTS',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ACCOUNT_DELETED = 'ACCOUNT_DELETED',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  NOTIFICATION_NOT_FOUND = 'NOTIFICATION_NOT_FOUND',
  NOTIFICATION_ALREADY_EXISTS = 'NOTIFICATION_ALREADY_EXISTS',
  ORGANIZATION_ALREADY_EXISTS = 'ORGANIZATION_ALREADY_EXISTS',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  USER_NOT_IN_ORGANIZATION = 'USER_NOT_IN_ORGANIZATION',
  USER_ALREADY_IN_ORGANIZATION = 'USER_ALREADY_IN_ORGANIZATION',
  USER_ALREADY_IN_PROJECT = 'USER_ALREADY_IN_PROJECT',
  MESSAGE_NOT_FOUND = 'MESSAGE_NOT_FOUND',
  MESSAGE_ALREADY_EXISTS = 'MESSAGE_ALREADY_EXISTS',
  REACTION_NOT_FOUND = 'REACTION_NOT_FOUND',
  REACTION_ALREADY_EXISTS = 'REACTION_ALREADY_EXISTS',
  REACTION_FOR_USER_EXISTS = 'REACTION_FOR_USER_EXISTS',
  USER_ALREADY_IN_TASK = 'USER_ALREADY_IN_TASK',
  TASK_ALREADY_HAS_TAG = 'TASK_ALREADY_HAS_TAG',
  TASK_TAG_NOT_FOUND = 'TASK_TAG_NOT_FOUND',
  TASK_ATTACHMENT_NOT_FOUND = 'TASK_ATTACHMENT_NOT_FOUND',
  USER_ALREADY_FOLLOWING_PROJECT = 'USER_ALREADY_FOLLOWING_PROJECT',
  PROJECT_FOLLOWER_NOT_FOUND = 'PROJECT_FOLLOWER_NOT_FOUND',
  USER_ALREADY_FOLLOWING_LIST = 'USER_ALREADY_FOLLOWING_LIST',
  LIST_FOLLOWER_NOT_FOUND = 'LIST_FOLLOWER_NOT_FOUND',
  USER_ALREADY_FOLLOWING_TASK = 'USER_ALREADY_FOLLOWING_TASK',
  TASK_FOLLOWER_NOT_FOUND = 'TASK_FOLLOWER_NOT_FOUND',
  LIST_WITH_ORDER_ALREADY_EXISTS = 'LIST_WITH_ORDER_ALREADY_EXISTS',
  TASK_WITH_ORDER_ALREADY_EXISTS = 'TASK_WITH_ORDER_ALREADY_EXISTS',
  CHECKLIST_ITEM_WITH_ORDER_ALREADY_EXISTS = 'CHECKLIST_ITEM_WITH_ORDER_ALREADY_EXISTS',
  CUSTOM_FIELD_INCORRECT = 'CUSTOM_FIELD_INCORRECT',
  CUSTOM_RELATION_INCORRECT = 'CUSTOM_RELATION_INCORRECT',
  EXTENSION_NOT_FOUND = 'EXTENSION_NOT_FOUND',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  INVITER_NOT_FOUND = 'INVITER_NOT_FOUND',
  INVITED_USER_NOT_FOUND = 'INVITED_USER_NOT_FOUND',
  USER_LIMIT_EXCEEDED = 'USER_LIMIT_EXCEEDED',
  NOT_PERMITTED = 'NOT_PERMITTED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  RESPONSE_HANDLER_NOT_FOUND = 'RESPONSE_HANDLER_NOT_FOUND',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
}

export default {
  [Type.NOT_IMPLEMENTED]: new Error('Method not implemented.'),
}
