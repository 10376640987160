export enum UserTrackerEvent {
  cardCreated = 'Card Created',
  cardEdited = 'Card Edited',
  followerAdded = 'Follower Added',
  spaceCreated = 'Space Created',
  workspaceCreated = 'Workspace Created',
  spaceEdited = 'Space Edited',
  spaceListCreated = 'Space List Created',
  messageSend = 'Message Send',
  memberInvited = 'Member Invited',
  memberOnboard = 'Member Onboard',
  memberSignedUp = 'Member Signed Up',
  memberMentioned = 'Member Mentioned',
  memberRemoved = 'Member Removed',
  notificationItem = 'Notification Item',
  settingChanged = 'Setting Changed',
  pageOpen = 'Page Open',
  addonStatusChanged = 'Addon Status Changed',
  subscriptionAdded = 'Subscription Added',
  featureUsed = 'Feature Used',
  billingPeriodChanged = 'Billing Period Changed',
  filterUsed = 'Filter Used',
  GroupCreated = 'Group Created',
  GroupEdited = 'Group Edited',
  GroupAssigned = 'Group Assigned',
}
