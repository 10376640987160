export const domain = 'App';

export const onInit = `${domain}/onInit`;
export const onInitSuccess = `${domain}/onInitSuccess`;
export const onInitFailure = `${domain}/onInitFailure`;

export const onInitModels = `${domain}/onInitModels`;
export const onInitBackgroundResources = `${domain}/onInitBackgroundResources`;

export const onSetQuickSwitcherState = `${domain}/onSetQuickSwitcherState`;
export const onSetIsWorkspaceModalOpen = `${domain}/onSetIsWorkspaceModalOpen`;

export const onSetIsUserIdle = `${domain}/onSetIsUserIdle`;
export const onSetIsPageVisible = `${domain}/onSetIsPageVisible`;
export const onSetIsWorkspacesPanelVisible = `${domain}/onSetIsWorkspacesPanelVisible`;
export const onSetOpenedCardDetailsModalId = `${domain}/onSetOpenedCardDetailsModalId`;

export const onSetDoUserWantToUseNotSupportedBrowser = `${domain}/onSetDoUserWantToUseNotSupportedBrowser`;

export const onSetAppHasLoaded = `${domain}/onSetAppHasLoaded`;

export const onReloadModels = `${domain}/onReloadModels`;

export const onCleanModels = `${domain}/onCleanModels`;

export const onReceiveNewAppVersion = `${domain}/onReceiveNewAppVersion`;
export const onSetIsEmbedded = `${domain}/onSetIsEmbedded`;

export const onSetIsOnline = `${domain}/onSetIsOnline`